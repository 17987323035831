import React, {useState, useEffect} from "react";
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';
import styled from "styled-components";
import  {FormGroup ,Label} from "reactstrap";
import { InputStyled,Legal,DraggerStyled } from "../DesignSystem/SelfTissus/Forms";
import BoutonNoirST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import { FaCloudUploadAlt } from "react-icons/fa";
import { message } from 'antd';
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
  padding: 20px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 10px;
  }
`;

const Txt = styled.div`
color: ${props => props.theme.beigeClair}; 
text-align: left; font: 500 20px Unbounded, sans-serif; position: relative;
  @media only screen and (max-width: 992px) {
    width: 100%;
    text-align:center;
  }
`;


const FormStyled = styled(FormGroup)`
position: relative;
display: flex;
width: 100%;
max-width: 600px;
`
export default function FormContactST({data}) {
  const [magasins, setMagasins] = useState([]);
  const [demandes, setDemandes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const [fileList, setFileList] = useState([]);
  const [className, setClassName] = useState("");
  const [form, setForm] = useState({
    Nom: "",
    Prenom: "",
    Mail: "",
    Sujet: "",
    uIdOperation: "",
    IdExploitant: "",
    Demande: "",
    TypeDemande: "",
    WebUpload: [],
  });

  const props = {
    name: 'file',
    multiple: true,
    action: '/users/webupload',
    // listType: "picture-card",


    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    onPreview: async file => {
      if (!file.preview) {

        file.preview = await new Promise(resolve => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
        // setPreviewImage(file.preview);
        // setPreviewVisible(true);

      }
    },
  };

  useEffect(() => {
    setIsLoading(true);
    axios.post(`/users/magasinliste`,{Limite : 20,OFFSET : "" })
    .then((response) => {
      setMagasins(response?.data?.Magasin);
        setIsLoading(false);
    })
    .catch((err) => {
      console.log("err", err);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios.post(`/users/typedemandeliste`)
    .then((response) => {
      setDemandes(response?.data?.TypeDemande);
      setIsLoading(false);
    })
    .catch((err) => {
      console.log("err", err);
      setIsLoading(false);
    });
  }, []);

  const sendMsg = () => {
    console.log("form", form);
    if (!form.Prenom || !form.Nom || !form.Mail) {
      setClassName("error");
      setIsErrorVisible(true);
      setTxt("Veuillez remplir tous les champs obligatoires");
    } else {
    setClassName("");
    setIsLoading(true);
    axios.post(`/users/demandesclientajout`, form)
    .then((response) => {
      console.log("response", response);
      setIsLoading(false);
      setIsErrorVisible(true);
      setTxt("Votre demande a bien été prise en compte");
      setClassName("");
      setForm({
        Nom: "",
        Prenom: "",
        Mail: "",
        Sujet: "",
        uIdOperation: "",
        IdExploitant: "",
        Demande: "",
        TypeDemande: "",
        WebUpload: [],
      });
      setFileList([]);
    })
    .catch((err) => {
      console.log("err", err);
      setIsLoading(false);
      setIsErrorVisible(true);
      setTxt("Une erreur est survenue. Veuillez réessayer plus tard.");
    });
   }
  }

 
    return (
        <Wrap>
          {isErrorVisible && <PopErrorST message={txt} titre={"Contact"} onClick={() => setIsErrorVisible(false)}/>}
          {isLoading && <SpinnerST />}
            <Txt> 
              <h1>{data?.Titre}</h1>
              <div>{ReactHtmlParser(data?.Corps)}</div>
              </Txt>
                <FormStyled floating>
                  <InputStyled
                      id="prenom"
                      name="prenom"
                      placeholder="Prénom"
                      type="text"
                      autoComplete="on"
                      className={className}
                      value={form.Prenom}
                      onChange={(e) => setForm({...form, Prenom: e.target.value})}
                  />
                  <Label for="prenom">Prénom</Label>
                  </FormStyled>

                <FormStyled floating>
                  <InputStyled
                        id="nom"
                        name="nom"
                        placeholder="Nom"
                        type="text"
                        autoComplete="on"
                        className={className}
                        value={form.Nom}
                        onChange={(e) => setForm({...form, Nom: e.target.value})}
                  />
                  <Label for="nom">Nom</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                      id="exampleEmail"
                      name="email"
                      placeholder="E-mail"
                      type="email"
                      autoComplete="on"
                      className={className}
                      value={form.Mail}
                      onChange={(e) => setForm({...form, Mail: e.target.value})}
                  />
                  <Label for="exampleEmail">E-mail</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                    id="type"
                    name="type"
                    placeholder="Type de demande"
                    type="select"
                    value={form.TypeDemande}
                    onChange={(e) => setForm({...form, TypeDemande: e.target.value})}
                  >
                    <option>Choisir un type de demande</option>
                    {
                      demandes && demandes.map((demande, index) => {
                        if (demande?.ID === 5) {
                          return null; // Skip this option
                        }
                        return (
                          <option key={index} value={demande?.ID}>{demande?.Libelle}</option>
                        )
                      })
                    }
                    </InputStyled>
                  <Label for="magasin">Type de demande</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                    id="magasin"
                    name="magasin"
                    placeholder="Votre magasin"
                    type="select"
                    value={form.IdExploitant}
                    onChange={(e) => setForm({...form, IdExploitant: e.target.value})}  
                  >
                    <option>Choisir un magasin</option>
                    {
                      magasins && magasins.map((magasin, index) => {
                        return (
                          <option key={index} value={magasin?.uiD}>{magasin?.NomCommercial}</option>
                        )
                      })
                    }
                    </InputStyled>
                  <Label for="magasin">Votre magasin</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                    id="commande"
                    name="commande"
                    placeholder="Numéro de commande"
                    type="text"
                    autoComplete="on"
                    value={form.uIdOperation}
                    onChange={(e) => setForm({...form, uIdOperation: e.target.value})}
                />
                  <Label for="commande">Numéro de commande</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                    id="sujet"
                    name="sujet"
                    placeholder="Sujet"
                    type="text"
                    autoComplete="on"
                    value={form.Sujet}
                    onChange={(e) => setForm({...form, Sujet: e.target.value})}
                />
                  <Label for="sujet">Sujet</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                    id="demande"
                    name="demande"
                    placeholder="Demande"
                    type="textarea"
                    autoComplete="on"
                    value={form.Demande}
                    onChange={(e) => setForm({...form, Demande: e.target.value})}
                />
                  <Label for="demande">Demande</Label>
                </FormStyled>

                <DraggerStyled {...props} className="w600" fileList={fileList} itemRender={(originNode, file, fileList) => {
                  return (
                    <div>
                      {originNode}
                    </div>
                  );
                  }} 
                  onChange={(info) => {
                    setFileList(info.fileList);
                    const { status } = info.file;
                    console.log(info.file, "info.file");
                    if (status !== 'uploading') {
                      console.log(info.file, info.fileList);
                    }
                    if (status === 'done') {
                      message.success(`Votre document : ${info.file.name}  est chargé.`);
                      console.log(info.file.response, "success");
                      setForm({ ...form, WebUpload: info.file.response.WebUpload })
                    } else if (status === 'error') {
                      message.error(`${info.file.name} Erreur lors du chargement.`);
                    }
                  }}>
                  <p className="ant-upload-drag-icon">
                    <FaCloudUploadAlt />
                  </p>
                  <p className="ant-upload-text">Cliquez ici ou déposez un fichier dans cette zone pour l'envoyer</p>
                  <p className="ant-upload-hint">
                    Vous pouvez envoyer plusieurs fichiers en même temps.<br></br>
                    Ne pas envoyer d'informations confidentielles.
                  </p>
                </DraggerStyled>
                <BoutonNoirST lib={"Envoyer votre demande"} onClick={() => sendMsg()} />
                {/* <Legal>
                  {data?.Corps}
                </Legal> */}
               
          </Wrap>
  );
}
