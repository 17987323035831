import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { useLocation } from '@reach/router';
import {  Breadcrumb } from 'antd';
import {  HomeOutlined, UserOutlined  } from '@ant-design/icons';
import { FaHome } from "react-icons/fa";
import {routes} from "../../Router";
import { Route } from 'react-router-dom';
import pathToRegexp from 'path-to-regexp';
import { BreadcrumbContext } from '../../pages/dispatcher';
import { Link } from "@reach/router";

const Wrapper = styled.div`
display: flex; 
flex-direction: column; 
align-items: flex-start; 
justify-content: flex-start; 
position: relative;
width: 100%;
padding: 5px 5px ;
background-color: #fff;
z-index: 2;
@media (max-width: 768px) {
  /* display: none; */
}
`;

const LinkStyled = styled(Link)`
text-align: left; 
text-decoration: none;
font: 400 16px Epilogue, sans-serif; 
position: relative;
cursor: pointer;
border-bottom: none !important;
color: ${(props) => props.theme.bleuHP} !important;
  &:hover {
    color: ${(props) => props.theme.greenST} !important;
    text-decoration: none;
    background-color: transparent !important;
  }
  &.actif {
    font-weight: 600;
  }
`;

export default function ArianeST({data}) {
  if (!Array.isArray(data)) {
    return null; // Don't render the component if data is not an array
  }
  
  const breadcrumbItems = data?.slice()?.sort((a, b) => data?.indexOf(b) - data?.indexOf(a))?.map((value) => {
    const url = value?.URL;
    const title = value?.URL === "/" ? <HomeOutlined /> : value?.Lib;
    return {
      href: url,
      title: title,
    };
  });
// Check if there is only one item with URL "/"
const shouldRenderBreadcrumb = breadcrumbItems.length > 1 || (breadcrumbItems.length === 1 && breadcrumbItems[0].href !== "/");
if (!shouldRenderBreadcrumb) {
  return null; // Don't render the component if there's only one item with URL "/"
}
  return (
    <Wrapper>
      <Breadcrumb separator=">" items={breadcrumbItems} />
    </Wrapper>
  );
}

