import React, {useState} from "react";
import axios from "axios";
import styled from "styled-components";
import { FormGroupStyled2 } from "../DesignSystem/SelfTissus/ElementsST";
import { Buts } from "../DesignSystem/SelfTissus/ContainersST";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";
import RatingStarsST from "../RatingST/RatingStars";
import  {Label} from "reactstrap";
import { InputStyled,DraggerStyled } from "../DesignSystem/SelfTissus/Forms";
import BoutonJauneST from "../DesignSystem/SelfTissus/BoutonJaune";
import BoutonJauneInverseST from "../DesignSystem/SelfTissus/BoutonJauneInverse";
import { message, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { FaCloudUploadAlt } from "react-icons/fa";
import { ButFermer } from "../DesignSystem/PopUpST";
import { IoCloseCircleOutline } from "react-icons/io5";
const { Dragger } = Upload;

const Note = styled.div`
padding: 20px 0;
`

export default function FormRealisationST({className,placeholder,mode,onChangeOpen}) {

    const [dataSearch, setDataSearch] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [txt, setTxt] = useState('');
    const [fileList, setFileList] = useState([]);
    const [form, setForm] = useState({
      Prenom: "",
      Mail: "",
      Demande: "",
      TypeDemande: 5,
      WebUpload: [],
    });
  
    const props = {
      name: 'file',
      multiple: true,
      action: '/users/webupload',
      // listType: "picture-card",
  
  
      onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
      },
      onPreview: async file => {
        if (!file.preview) {
  
          file.preview = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
          // setPreviewImage(file.preview);
          // setPreviewVisible(true);
  
        }
      },
    };

    const sendReal = () => {
      if (!form.Prenom || !form.Mail || !form.Demande || !form.WebUpload) {
        setIsErrorVisible(true);
        if (!form.Prenom) {
          setTxt("Veuillez entrer votre prénom");
        } else if (!form.Mail) {
          setTxt("Veuillez entrer votre email");
        } else if (!form.Demande) {
          setTxt("Veuillez entrer votre demande");
        } else if (!form.WebUpload) {
          setTxt("Veuillez joindre un fichier");
        }
        return;
      }
      setIsLoading(true);
      axios.post(`/users/demandesclientajout`, form)
      .then((response) => {
        console.log("response", response);
        setIsLoading(false);
        setIsErrorVisible(true);
        setTxt("Votre réalisation a bien été prise envoyée");
        setForm({});
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
        setIsErrorVisible(true);
        setTxt("Une erreur est survenue. Veuillez réessayer plus tard.");
      });
    }
    return(
      <div className="form" style={{width:"100%",position:"relative"}}>
        <ButFermer onClick={() => onChangeOpen(false)}><IoCloseCircleOutline/></ButFermer>
        {isErrorVisible && <PopErrorST message={txt} titre={"Réalisations"} onClick={() => setIsErrorVisible(false)}/>}
        {isLoading && <SpinnerST />}
        <DraggerStyled {...props} fileList={fileList} itemRender={(originNode, file, fileList) => {
            return (
              <div>
                {originNode}
              </div>
            );
            }} 
            onChange={(info) => {
              setFileList(info.fileList);
              const { status } = info.file;
              console.log(info.file, "info.file");
              if (status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (status === 'done') {
                message.success(`Votre document : ${info.file.name}  est chargé.`);
                console.log(info.file.response, "success");
                setForm({ ...form, WebUpload: info.file.response.WebUpload })
              } else if (status === 'error') {
                message.error(`${info.file.name} Erreur lors du chargement.`);
              }
            }}>
            <p className="ant-upload-drag-icon">
              <FaCloudUploadAlt />
            </p>
            <p className="ant-upload-text">Cliquez ici ou déposez un fichier dans cette zone pour l'envoyer</p>
            <p className="ant-upload-hint">
              Vous pouvez envoyer plusieurs fichiers en même temps.<br></br>
              Ne pas envoyer d'informations confidentielles.
            </p>
          </DraggerStyled>
        <FormGroupStyled2 floating className={className}>
            <InputStyled
            id="prenom"
            name="search"
            placeholder={"Prénom"}
            type="text"
            autoComplete="on"
            onChange={(e) => setForm({...form, Prenom: e.target.value})}
            />
            <Label for="prenom">Prénom</Label>
        </FormGroupStyled2>
        <FormGroupStyled2 floating className={className}>
            <InputStyled
            id="email"
            name="search"
            placeholder={"Email"}
            type="email"
            autoComplete="on"
            onChange={(e) => setForm({...form, Mail: e.target.value})}
            />
            <Label for="email">Email</Label>
        </FormGroupStyled2>

        <FormGroupStyled2 floating className={className}>
            <InputStyled
            id="realisation"
            name="search"
            placeholder={"Réalisation"}
            type="textarea"
            onChange={(e) => setForm({...form, Demande: e.target.value})}
            />
            <Label for="realisation">Réalisation</Label>
        </FormGroupStyled2>
       
        
      <p className="center mt50">En soumettant votre réalisation, vous acceptez les <a href="/informations-legales/cgu" target="_blank" rel="nofollow noopener"> conditions générales d'utilisation</a> de Self Tissus.</p>
      <Buts>
        <BoutonJauneST lib={"Annuler"} onClick={() => onChangeOpen(false)}/>
        <BoutonJauneInverseST lib={"Soumettre la Réalisation"} onClick={() => {sendReal()}}/>
      </Buts>
      

    </div>
    )
}

