import React, {useState} from "react";
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import styled from 'styled-components';
import imgBlogDefault from "../../images/blog.webp"
import { Section, WrapperBlog } from "../DesignSystem/SelfTissus/ContainersST";
import { GiSewingNeedle } from "react-icons/gi";
import { IoArrowBack } from "react-icons/io5";

const Img = styled.img`
flex-basis: 40%;
max-height: 530px;
box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
@media screen and (max-width: 786px) {
    flex-basis: 100%;
    max-width: 100%;
    /* max-height: 300px; */
}
`;

const Wrap = styled.div`
width: 100%;
&>.cont {
    &>img, &>p>img, &>div>img{
        max-width: 600px;
        margin: 25px;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
    }
}
@media screen and (max-width: 992px) {
    flex-wrap: wrap;
}
`;

const Infos = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-wrap: wrap;
padding: 10px 0 20px 0;
&>svg {
    color: ${props => props.theme.redST};
    font-size: 18px;
    stroke-width: 30px;
}
&>span, a {
color :${props => props.theme.grey09ST} !important;
font-size: 23px;
padding: 0 20px;
}
`;

const ImgBlog = styled.img`
width: 80%;
max-width: 600px;
box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.2);
@media screen and (max-width: 786px) {
    width: 100%;
    max-width: 100%;
}
`;

const Back = styled.a`
display: flex;
align-items: center;
justify-content: flex-end;
gap: 10px;
font-size: 15px;
color: ${props => props.theme.grey06ST};
`;

export default function BlogFicheST({data}) {
  console.log("data", data);
function FormateDate(date){
  if(date !== undefined && date !== null && date !== ""){
    const dateObj = new Date(date);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return dateObj.toLocaleDateString('fr-FR', options);
  }
  return "";
}

  return (
      <Section className="noPadding">
         <WrapperBlog>
         <h1 className="center linesGreen">{data?.Titre}</h1>
         <ImgBlog alt="example" src={data?.image?.url ? data?.image?.url : imgBlogDefault} />
            <Infos>
            {/* <span>{"Auteur"}</span><GiSewingNeedle/> */}
            {/* <a>{"Categorie"}</a><GiSewingNeedle/> */}
            <span>{FormateDate(data?.Date)}</span><GiSewingNeedle/>
            {/* <span>{" 3 minutes "}</span> */}
            </Infos>    
            {/* <TitreH2TextST data={data} padding={"0"}/> */}
            {/* <Wrap>
                <Inside>
                    <Txt>
                        <h2 className="homemadeTitle green initial">{"Titre"}</h2>
                        {ReactHtmlParser(data?.Corps)}
                    </Txt>
                </Inside>
                <Img src={"https://selftissus.myshopify.com/cdn/shop/files/1705502482_0_260x.jpg?v=34772126533"}
                onError={(e) => {
                e.target.onerror = null;
                e.target.src = picDefault;
                }} loading="lazy"
                    />
            </Wrap>
            <Wrap>
                <Img src={"https://selftissus.myshopify.com/cdn/shop/files/1705502482_0_260x.jpg?v=34772126533"}
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = picDefault;
                    }} loading="lazy"
                    />
                <Inside>
                    <Txt>
                        <h2 className="homemadeTitle green initial">{data?.Titre}</h2>
                        {ReactHtmlParser(data?.Corps)}
                    </Txt>
                </Inside>
              
            </Wrap> */}
            <Wrap>
                <ReactMarkdown className="cont" remarkPlugins={[remarkBreaks]}>{data?.Contenu}</ReactMarkdown>
                {/* <div>{data?.Contenu}</div> */}
            </Wrap>
            {/* <BoutonNoirLnkST href={"#"} lib={"Exemple bouton"} target={"_self"}/> */}
            
        </WrapperBlog>
       
        {/* <BlogProduitsST titre={"Voir aussi : "}/> */}
        <Back href="/je-fais-moi-meme"><IoArrowBack/>Je fais moi-même</Back>
        {/* <BlogListeST titre={"Voir aussi : "}/> */}
      </Section>
  );
}