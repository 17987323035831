import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import ReactDOM from "react-dom";
import styled from "styled-components";
import  {FormGroup ,Label, Input} from "reactstrap";
import { InputStyled,InputCheckStyled } from "../DesignSystem/SelfTissus/Forms";
import BoutonST from "../DesignSystem/SelfTissus/Bouton";
import {Socials, LinkStyledSocial} from "../DesignSystem/SelfTissus/ElementsST";
import { FaFacebook } from "react-icons/fa";
import { FaPinterest } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import img from "../../assets/images/img.jpg";
import { PopUp, Wrap, ButFermer } from "../DesignSystem/PopUpST";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";

// const Wrap = styled.div`
// display: flex; 
// flex-direction: column; 
// gap: 20px; 
// z-index: 1;
// align-items: center; 
// justify-content: center;
// width: 100%;

//   padding: 20px;
//   @media only screen and (max-width: 992px) {
//     width: 100%;
//     gap: 44px;
//   }
// `;

const Bck = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
/* z-index: -1; */
background-image: url(${img});
background-size: cover;
background-position: center;
opacity: 0.2;
`;

const Txt = styled.div`
color: ${props => props.theme.beigeClair}; 
text-align: left; font: 500 20px Unbounded, sans-serif; position: relative;
  @media only screen and (max-width: 992px) {
    width: 100%;
    text-align:center;
  }
`;

const FormStyled = styled(FormGroup)`
position: relative;
display: flex;
align-items: center;
gap: 10px;
width: 100%;
max-width: 600px;
`
export default function FormNewsletterST({onClose, visible}) {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const user = useSelector(state => state?.user?.user?.Contact);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
    
  const subscribe = () => {
    if (!isChecked) {
      setIsErrorVisible(true);
      setTxt("Vous devez accepter la charte de données personnelles");
      return;
    }
    setIsLoading(true);
        axios.post(`/users/membresauvenewsletter`, {Newsletter: true})
          .then((response) => {
            console.log("response", response);
            setIsLoading(false);
            setIsErrorVisible(true);
            setTxt("Votre demande a bien été prise en compte");
            onClose();
            setIsChecked(false);
            dispatch({
              type: "UPDATE_USER",
              payload: {
                Contact: {
                  ...user,
                  Newsletter: true,
                },
              },
            });
          })
          .catch((err) => {
            console.log("err", err);
            setIsErrorVisible(true);
            setTxt("Une erreur est survenue, veuillez réessayer plus tard");
            setIsLoading(false);
            dispatch({type: "DELETE_USER"})
          });
    }
    return (
      <>
       {isLoading && <SpinnerST />}
       {isErrorVisible && <PopErrorST message={txt} classNameBut={"white"} titre={"Newsletter"} onClick={() => setIsErrorVisible(false)}/>}
        {visible && ReactDOM.createPortal(<PopUp position={"fixed"}>
          <ButFermer onClick={onClose}>X</ButFermer>
          <Wrap>
          <Bck></Bck>
            <Txt> 
              <h3 className="center">Rejoignez notre newsletter</h3>
              <p className="center fs17">Rejoignez notre liste de diffusion pour être informé des tendances, des nouveautés et de notre actualité.</p>
              </Txt>
             <FormStyled floating>
                    <InputStyled
                        id="exampleEmail"
                        name="email"
                        placeholder="Votre e-mail"
                        type="email"
                        value={user?.Mail}
                    />
                     <Label for="exampleEmail">Votre e-mail</Label>
                </FormStyled>
             <FormStyled>
                    <InputStyled
                        id="cond"
                        name="email"
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                     <Label for="cond" style={{textAlign:"left",margin:0}}>
                     Je reconnais avoir pris connaissance de la <a href="/informations-legales/donnees-personnelles" target="_blank" rel="noopener noreferrer">charte de données personnelles</a></Label>
                </FormStyled>
                <BoutonST lib={"Inscription"} 
                    background={props => props.theme.orangeST}
                    width={"100%"}
                    onClick={() => {
                      subscribe();
                    }}
                    />
                <p className="center fs17">En vous inscrivant à la newsletter vous acceptez de recevoir des mails de Self Tissus sur notre actualité, nos offres en cours et tout ce que nous partageons avec notre communauté de passionné·e·s. Nous ne partageons pas vos données personnelles à des tiers. Vous pouvez à tout moment vous désinscrire au bas de chaque email envoyé.</p>
                <Socials>
                  <LinkStyledSocial href="https://www.facebook.com/SelfTissus" target="_blank" rel="noopener noreferrer"><FaFacebook/></LinkStyledSocial>
                  <LinkStyledSocial href="https://www.instagram.com/self_tissus/" target="_blank" rel="noopener noreferrer"><FaInstagram/></LinkStyledSocial>
                  {/* <LinkStyledSocial href=""><FaTiktok/></LinkStyledSocial> */}
                  <LinkStyledSocial href="https://www.youtube.com/@selftissusfrance" target="_blank" rel="noopener noreferrer"><FaYoutube/></LinkStyledSocial>
                  <LinkStyledSocial href="https://fr.linkedin.com/company/self-tissus" target="_blank" rel="noopener noreferrer"><FaLinkedinIn/></LinkStyledSocial>
                  <LinkStyledSocial href="https://www.pinterest.fr/selftissusfr/" target="_blank" rel="noopener noreferrer"><FaPinterest/></LinkStyledSocial>
                </Socials>
          </Wrap></PopUp>,
          document.body)}
      </>
  );
}
