import React from 'react';
import ReactDOM from 'react-dom';
import styled, {keyframes,useTheme} from 'styled-components';
import Logo from "../../assets/images/Logo.svg";
import picDefault from "../../assets/images/image.png";
import { ColorRing, Oval,RotatingTriangles } from 'react-loader-spinner'

const loader = keyframes`
0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`

const slide = keyframes`
    0% {
    left: -50;
    top: 0;
  }
  /* 50% {
    left: 130px;
    top: 0px;
  } */
  100% {
    left: 130px;
    top: 0;
  } 
  `

const LoaderContainer=styled.div`
width: 100%;
height: 100%;
top:0;
left:0;
position: fixed;
backdrop-filter: blur(10px) saturate(109%);
background: #79b69f6e;
z-index: 10000;
`

const LoaderContent=styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
overflow: hidden;
padding: 23px 6px;
&>svg {
  position: absolute;
  z-index: 1;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
`



const PageLoading = () => {
  const theme = useTheme();
    const color1 = theme.greenST;
    const color3 = theme.jaune01ST
    const color4 = "#000";
  return (
    <>
     {ReactDOM.createPortal(
      <LoaderContainer id="loading-wrapper">
        <LoaderContent>
          
          <img onError={(e) => {
            e.target.onerror = null;
            e.target.src = picDefault;
          }} loading="lazy" src={Logo}/>
          <RotatingTriangles
              visible={true}
              height="160"
              width="160"
              colors={[color3, color1, color4]}
              ariaLabel="rotating-triangles-loading"
            />
          </LoaderContent>
      </LoaderContainer>,
      document.body
    )}
    </>
  );
};

export default PageLoading;