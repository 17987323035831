import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useGeolocated } from "react-geolocated";
import axios from 'axios';
import { InputStyled,FormConnect } from "../DesignSystem/SelfTissus/Forms";
import { AiOutlineCloseCircle } from "react-icons/ai";

 const UlStyled = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    border-top: none;
    margin-top: 0.5rem;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

const LiStyled = styled.li`
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
    :hover {
        background-color: #f0f0f0;
    }

    .selected {
        color: white;
    }
`
const WrapperInput = styled.div`
    position:relative;
    svg {
        position:absolute; 
        right:8px; 
        top:50%;
        transform: translateY(-50%);
        color: ${({ theme }) => theme.greenST};
        cursor: pointer;    
        width: 30px;
        height: 30px;
    }
`

export default function AdresseAutocomplete({ onChange, isRequired, setInputValue, inputValue, placeHolder }) {

    const [options, setOptions] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    // Géolocalisation
    const { isGeolocationAvailable, isGeolocationEnabled, coords } = useGeolocated();

    useEffect(() => {
        if (!isGeolocationAvailable) {
            setErrorMessage("La géolocalisation n'est pas disponible sur ce navigateur.");
        } else if (!isGeolocationEnabled) {
            setErrorMessage("La géolocalisation n'est pas activée.");
        } else if (!coords) {
            setErrorMessage(""); // En attente de la position
        } else {
            setErrorMessage(false);
        }
    }, [isGeolocationAvailable, isGeolocationEnabled, coords]);


    const fetchAddresses = (inputValue) => {
        setInputValue({
            Adresse: inputValue,
            cp: "",
            ville: "",
            pays: "France"
        });

        axios.get(
            `https://api-adresse.data.gouv.fr/search/?q=${inputValue}&limit=5`
        ).then(response => {
            setOptions(response.data);
            console.log("autocomplet", response.data);
        }).catch(error => {
            // console.error(error);
        });
    };

    const handleIconClick = () => {
        setInputValue({
            Adresse: "",
            cp: "",
            ville: "",
            pays: ""
        }); // Efface le contenu du champ de saisie 

    };

    const handleInputChange = (e) => {
        const newInputValue = e.target.value;
        fetchAddresses(newInputValue);
    };

    const handleOptionSelect = (selectedOption) => {
        const { city, postcode,label,name } = selectedOption.properties;
    
        setInputValue({
            ...inputValue,
            Adresse: name,
            cp: postcode,
            ville: city,
            pays: "France"
        })
        onChange(selectedOption.properties);
        setOptions([]); // Effacer les options après sélection
        const element = document.getElementById('pointsRelais');
        if (element) {
        const offset = window.innerWidth > 992 ? 200 : 0;
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scrollTo({ top: elementPosition, behavior: 'smooth' });
        }
    };

    const handleGeolocateClick = (e) => {
        e.preventDefault();

        if (coords) {
            // Obtenir l'adresse inversée à partir des coordonnées géographiques
            axios.get(`https://api-adresse.data.gouv.fr/reverse/?lon=${coords.longitude}&lat=${coords.latitude}`)
                .then(response => {
                    const {  name,postcode,city } = response.data.features[0].properties;
                    // console.log("geolocate", response.data)
                    setInputValue({
                        ...inputValue,
                        Adresse: name,
                        cp: postcode,
                        ville: city,
                        pays: "France"
                    })
                    onChange({ name,postcode,city,});
                })
                .catch(error => {
                    // console.error('Erreur lors de la récupération de l\'adresse inversée :', error);
                });
        }
    };


    return (
        <>
            <WrapperInput>
                <InputStyled
                    type="text"
                    value={inputValue.Adresse || ""}
                    onChange={handleInputChange}
                    placeholder={placeHolder}
                    required={isRequired}

                />
                <AiOutlineCloseCircle onClick={handleIconClick} />
            </WrapperInput>

            {inputValue && options.features && options.features.length > 0 && (
                <UlStyled>
                    {options?.features?.map((option) => (
                        <LiStyled key={option.properties.id} onClick={() => handleOptionSelect(option)}>
                            {option.properties.label}
                        </LiStyled>
                    ))}
                </UlStyled>
            )}

            {/* <DivStyled >
                <ButtonStyled
                    onClick={handleGeolocateClick}
                >
                    <BiCurrentLocation />   Me géolocaliser
                </ButtonStyled>
            </DivStyled> */}


         
        </>
    )
}