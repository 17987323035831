import React from "react";
import axios from "axios";
import {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import  {FormGroup ,Label, Input} from "reactstrap";
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";
import { AiOutlineArrowRight } from "react-icons/ai";
import FormConnexionST from "./FormConnexion";
import BoutonST from "../DesignSystem/SelfTissus/Bouton";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
  padding: 20px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 44px;
  }
`;

const Txt = styled.div` 
text-align: center; 
position: relative;
display: flex;
flex-direction: column;
gap: 10px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    text-align:center;
  }
`;

const ButtonStyled = styled.button`
background-color: transparent;
border: none;
cursor: pointer;
position: absolute;
right: 0;
top: 0;
overflow: visible;
display: flex;
width: 40px;
height: 100%;
border-radius: 50%;
& > svg {
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
    width: 18px;
    height: 18px;
    transition: all .3s ease;
}
&:hover{
 & > svg {
    fill: ${props => props.theme.redST};
 }
}
`

const FormStyled = styled(FormGroup)`
position: relative;
display: flex;
width: 100%;
gap: 10px;
max-width: 600px;
`
export default function FormNewsletterFooterST() {
  const user = useSelector(state => state?.user?.user?.Contact);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  
  const sendNewsInfo = (news) => {
    if (!isChecked && news) {
      setIsErrorVisible(true);
      setTxt("Vous devez accepter la charte de données personnelles");
      return;
    }
    setIsLoading(true);
        axios.post(`/users/membresauvenewsletter`, {Newsletter: true})
          .then((response) => {
            console.log("response", response);
            setIsLoading(false);
            setIsErrorVisible(true);
            setTxt("Votre demande a bien été prise en compte");
            dispatch({
              type: "UPDATE_USER",
              payload: {
                Contact: {
                  ...user,
                  Newsletter: news,
                },
              },
            });
          })
          .catch((err) => {
            console.log("err", err);
            setIsErrorVisible(true);
            setTxt("Une erreur est survenue, veuillez réessayer plus tard");
            setIsLoading(false);
            dispatch({type: "DELETE_USER"})
          });
    }

    return (
      user ? ( 
        <Wrap>
         {isLoading && <SpinnerST />}
         {isErrorVisible && <PopErrorST message={txt} titre={"Newsletter"} classNameBut={"white"} onClick={() => setIsErrorVisible(false)}/>}
          {!user?.Newsletter ? (
             <>
            <Txt> 
              <h2>Soyez au courant des dernières nouveautés</h2>
              <p className="center homemadeTitle fs20 green">Abonnez-vous à la newsletter et soyez au courant des nouveautés,<br></br>
              recevez des conseils et des offres !</p>
              </Txt>
             <FormStyled floating>
                    <InputStyled
                        id="exampleEmail"
                        name="email"
                        placeholder="E-mail"
                        type="email"
                        value={user?.Mail}
                        readOnly
                    />
                     <Label for="exampleEmail"> E-mail</Label>
                    <ButtonStyled onClick={() => sendNewsInfo(true)}>
                      <AiOutlineArrowRight/>
                    </ButtonStyled>

                </FormStyled>

                <FormStyled>
                  <InputStyled
                      id="cond"
                      name="cond"
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                  />
                  <Label for="cond" style={{textAlign:"left"}}>
                  Je reconnais avoir pris connaissance de la <a href="/informations-legales/donnees-personnelles" target="_blank" rel="noopener noreferrer">charte de données personnelles</a></Label>
                </FormStyled></> ) : (
                  <Txt>
                    <h2>Newsletter</h2>
                    <p className="center homemadeTitle fs20 green">Vous êtes inscrit à la newsletter</p>
                    <BoutonST className="medium" background={"#000"} lib="Se désinscrire" onClick={() => sendNewsInfo(false)} />
                  </Txt>
                )}
                
          </Wrap> 
        ): 
        (
          <Wrap>
          <Txt> 
            <h2>Soyez au courant des dernières nouveautés</h2>
            <p className="center homemadeTitle fs20 green">Connectez-vous ou créez un compte pour vous abonner à la newsletter et soyez au courant des nouveautés,<br></br>recevez des conseils et des offres !</p>
            </Txt>
            <FormConnexionST/>
        </Wrap> 
        )
  );
}
