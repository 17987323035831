import React, {useState, useEffect} from "react";
import axios from "axios";
import ReactMarkdown from 'react-markdown';
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import styled from 'styled-components';
import { FaRegClock } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import imgBlogDefault from "../../images/blog.webp"
import ReactHtmlParser from 'react-html-parser';
import { Section, WrapperBlogs } from "../DesignSystem/SelfTissus/ContainersST";
import BoutonTransparentBtnST from "../DesignSystem/SelfTissus/BoutonTransparentBtn";
import { Card } from 'antd';
const { Meta } = Card;

const CardStyled = styled(Card)`
  cursor: default;
  &>.ant-card-body>.contBlog> img,  &>.ant-card-body>.contBlog> div > img, &>.ant-card-body>.contBlog> p >img {
    display: none;
  } &>.ant-card-body>.contBlog> div, &>.ant-card-body>.contBlog> p  {
    display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  `;

const Infos = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding: 10px 0 20px 0;
  &>span {
    color :${props => props.theme.grey09ST};
    font-size: 13px;
  }
  `;

const Title = styled.h2`
margin: 0 0 20px 0;
font-size: 29px;
`


export default function BlogListeST({data}) {

const [isLoading, setIsLoading] = useState(false);
const [isLoadingMore, setIsLoadingMore] = useState(false);
const [dataBlogs, setDataBlogs] = useState([]);
const [dataBlogsNbr, setDataBlogsNbr] = useState(0);

function FormateDate(date){
  if(date !== undefined && date !== null && date !== ""){
    const dateObj = new Date(date);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return dateObj.toLocaleDateString('fr-FR', options);
  }
  return "";
}

useEffect(() => {
  setIsLoading(true);
  axios.post(`/users/blogliste`,{Limite : 20, OFFSET : 0})
  .then((response) => {
    console.log("response", response);
    setDataBlogs(response?.data?.Blogs);
    setDataBlogsNbr(response?.data?.BlogsNbr);
    setIsLoading(false);
  })
  .catch((err) => {
    console.log("err", err);
    setIsLoading(false);
  });
  } , []);

  const handleMoreBlogs = (event, offset, limit) => {
    event.preventDefault();
    setIsLoadingMore(true);
    axios.post(`/users/blogliste`,{Limite : limit, OFFSET : offset })
    .then((response) => {
      setDataBlogs([...dataBlogs, ...response?.data?.Blogs]);
      setIsLoadingMore(false);
    })
    .catch((err) => {
      setIsLoadingMore(false);
      console.log("err", err);
    });
  };


  return (
      <Section className="">
        {((dataBlogs?.length === 0 || dataBlogs === undefined) && !isLoading) ? <p className="center">Aucun blog pour le moment</p> 
        : <>
        {data?.Titre && data?.Titre !=="WebComposant" ? <h2 className="center">{data?.Titre}</h2> : null}
         <WrapperBlogs>
          {
            dataBlogs?.sort((a, b) => a.Tri - b.Tri).map((item, i) => {
              return (
                <CardStyled
                  key={i}
                  hoverable
                  className="cardBlog"
                  cover={<img alt="image de blog Self Tissus" src={item?.image?.url ? item?.image?.url : imgBlogDefault} 
                  // onClick={() => window.location.href = item?.BTNUrl}
                  />}
                >
                  <Infos>
                    <span>{FormateDate(item?.Date)}</span> 
                  </Infos>
                  <Title className="blog grey">{item?.Titre}</Title>
                  <ReactMarkdown className="contBlog">{item?.Contenu}</ReactMarkdown>
                  <a className="orange bold" href={item?.slug} target="_blank">Voir l'article</a>
                </CardStyled>
              );
            })
          }
            {dataBlogs?.length < dataBlogsNbr ? (
                        <div style={{width: "100%"}}>
                        <BoutonTransparentBtnST margin={"0 auto"} display={"block"}
                        onClick={(event) => handleMoreBlogs(event, dataBlogs?.length, 20)}
                        lib="Voir plus de blogs"
                        />
                        </div>
                      ) : null}
        </WrapperBlogs>
        </>
        }
        {isLoading && <SpinnerST />} 
        {isLoadingMore && <SpinnerST />} 
        
      </Section>
  );
}