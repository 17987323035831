import React, {useState, useEffect} from "react";
import styled from 'styled-components';
import { Section } from "../DesignSystem/SelfTissus/ContainersST";
import { Card } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { Layout } from 'antd';
import { 
    TabContent, TabPane, 
    Row, Col , Nav, NavItem, NavLink,
  } from 'reactstrap'; 
//   import { NavLinkStyled, NavItemStyled } from "../DesignSystem/Commons/Onglets";
import ArticlesListeST from "../ArticlesListe/ArticlesListe";
import AteliersListeST from "../Atelier/AteliersListe";

const NavStyled = styled(Nav)`
    background-color: #79b69fc9;
    padding: 10px 0;
    `
const NavItemStyled = styled(NavItem)`
margin: 0 30px;
 @media only screen and (max-width: 768px) {
    width: 75%;
    margin: 10px 0;
  }
`;
const NavLinkStyled = styled(NavLink)`
 font-size: 22px;
color: #fff !important;
text-align: center;
background-color: transparent !important;
border-radius: 0 !important;
border: 2px dashed transparent !important;
  &.active {
    border: 2px dashed #fff !important;
    cursor: default;
  }
  &:hover{
    border: 2px dashed #fff !important;
    text-decoration: none;
  }
`;

export default function ProduitsListeST({ cats, idCategorie}) {
  const [activeTab, setActiveTab] = useState(0);
  const [tabData, setTabData] = useState([]);
  const [nbrAteliers, setNbrAteliers] = useState(0);
  const [nbrArticles, setNbrArticles] = useState(0);

console.log("nbrAteliers", nbrAteliers)
console.log("nbrArticles", nbrArticles)
  const handleNbrAteliersChange = (newNbrAteliers) => {
    setNbrAteliers(newNbrAteliers);
  };

  const handleNbrArticlesChange = (newNbrArticles) => {
    setNbrArticles(newNbrArticles);
  };

  useEffect(() => {
    const newTabData = [];
    // if (dataArticles && dataArticles.length > 0) {
      newTabData.push({ label: "Articles", content: <ArticlesListeST cats={cats} idCategorie={idCategorie} onNbrArticlesChange={handleNbrArticlesChange}/> });
    // }
    // if (nbrAteliers > 0) {
      newTabData.push({
        label: idCategorie === "7c3656ab-606b-40c7-a3d2-24b2d459c2c1" ? "Consulter tous nos ateliers" : "Ateliers",
        content: <AteliersListeST idCategorie={idCategorie} onNbrAteliersChange={handleNbrAteliersChange} />
      });
    // }
    setTabData(newTabData);
  }, [cats]);

  useEffect(() => {
    if (tabData.length > 0) {
      const firstAvailableTab = tabData.findIndex(tab => 
        (tab.label === "Articles" && nbrArticles > 0) || 
        (tab.label === "Ateliers" && nbrAteliers > 0)
      );

      if (firstAvailableTab !== -1 && activeTab !== firstAvailableTab) {
        setActiveTab(firstAvailableTab); // Set to the first available tab with data
      }
    }
  }, [tabData, nbrArticles, nbrAteliers]);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };
    return (
    <>
   
      <Section className="noPadding">
        <div>
        <NavStyled tabs style={{ justifyContent: "center", marginBottom: 30 }} className="ignoreParent">
            {tabData.map((tab, index) => {
              if ((tab.label === "Articles" && nbrArticles > 0) || ((tab.label === "Ateliers" || tab.label === "Consulter tous nos ateliers") && nbrAteliers > 0)) {
                return (
                  <NavItemStyled className={index} key={index}>
                    <NavLinkStyled
                      className={activeTab === index ? 'active' : ''}
                      onClick={() => toggle(index)}
                    >
                      {tab.label}
                    </NavLinkStyled>
                  </NavItemStyled>
                );
              }
              return null; // Return null if the condition is not met
            })}
          </NavStyled>
            <TabContent activeTab={activeTab}>
              {tabData.map((tab, index) => (
                <TabPane key={index} tabId={index}>
                  <Row>
                    <Col sm="12">
                      {tab.content}
                    </Col>
                  </Row>
                </TabPane>
              ))}
            </TabContent>
            </div>
      </Section>
      </>
  );
}