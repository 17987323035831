import React from "react";
import { useState } from "react";
import styled, { useTheme } from "styled-components";
import { LineWave } from 'react-loader-spinner'

const DivLoading = styled.div`
position: absolute;
transform: translate(-50%, -50%);
top: ${props => props.top ? props.top : '50%'};
left: ${props => props.left ? props.left : '50%'};
display: flex;
flex-direction: column;
align-items: center;
margin: auto;
z-index: 99999;
&>div > svg > rect {
  width: 6px;
  height: 15px;
}
`;


export default function SpinnerInlineMiniWaveST({left, top}) {
    const theme = useTheme();
    const color1 = theme.jaune01ST;
    const color3 = theme.redST;
    const color4 = theme.greenST;
  let [loading, setLoading] = useState(true);

  return (
  <DivLoading className="sweet-loading" left={left} top={top}>
    <LineWave
     visible={true}
     height="150"
     width="200"
     color="#4fa94d"
     ariaLabel="line-wave-loading"
     wrapperStyle={{}}
     wrapperClass=""
     firstLineColor={color1}
     middleLineColor={color3}
     lastLineColor={color4}
    />
    </DivLoading>
  );
}