import React, {useState, useEffect} from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import styled from 'styled-components';
import { Section, WrapperProds } from "../DesignSystem/SelfTissus/ContainersST";
import { InputStyled, FormStyled, LabelStyled } from "../DesignSystem/SelfTissus/Forms";
import { Card, Badge } from 'antd';
import picDefault from "../../assets/images/image.png";
import img30 from "../../assets/images/30.png";
import img50 from "../../assets/images/50.png";
import img70 from "../../assets/images/70.png";
import ReactHtmlParser from 'react-html-parser';
import { FaCheck,FaRegWindowClose,FaCaretUp,FaCaretDown  } from "react-icons/fa";
import LienNoirBtnST from "../DesignSystem/SelfTissus/LienNoirBtn";
import BoutonST from "../DesignSystem/SelfTissus/Bouton";
import SpinnerInlineST from "../DesignSystem/SpinnersST/SpinnerInline";
import SpinnerInline2ST from "../DesignSystem/SpinnersST/SpinnerInline2";
import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import BoutonTransparentBtnST from "../DesignSystem/SelfTissus/BoutonTransparentBtn";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";
import { PiDotsThreeOutline } from "react-icons/pi";
import {FadeStyled} from "../DesignSystem/Commons/Containers";
import SpinnerInlineMiniST from "../DesignSystem/SpinnersST/SpinnerInlineMini";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

const { Header, Content, Footer, Sider } = Layout;

const SiderStyled = styled(Sider)`
min-width: 50% !important;
width: 70% !important;
background-color: #ffffff;
border-radius: 5px;
display: none;
@media screen and (max-width: 768px) {
  display: block;
}
`; 

const CardStyled = styled(Card)`
width: 320px;
background-color: transparent;
border: none;
border-radius: 0 !important;
/* min-height: 550px;
max-height: 550px; */
&>.ant-card-body {
  padding: 10px;
  border-radius: 0 !important;
}
@media screen and (max-width: 1100px) {
  width: 290px;
}
@media screen and (max-width: 992px) {
  width: 255px;
}
@media screen and (max-width: 768px) {
  width: 100%;
  margin: 0 auto;
  max-height: unset;
  min-height: unset;
}
&>h2 {
  font-size: 17px !important;
  margin: 0;
}
`;

const Img = styled.img`
min-height: 322px;
min-width: 322px;
width: 100%;
object-fit: cover;
border-radius: 0 !important;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
`;

const Titre = styled.div`
&>h2 {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media screen and (max-width: 768px) {
    white-space: normal;
  }
}

`;


const Prods = styled.div`
position: relative;
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
gap: 30px;
width: 80%;
@media screen and (max-width: 992px) {
  width: 70%;
}
@media screen and (max-width: 768px) {
  width: 100%;
}
`;

const FilterProds = styled.div`
width: 15%;
@media screen and (max-width: 992px) {
  width: 25%;
}
@media screen and (max-width: 768px) {
  display: none;
}
`;
const FilterProds2 = styled.div`
display: flex;
align-items: center;
width: 100%;
gap: 50px;
justify-content: flex-end;
@media screen and (max-width: 768px) {
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
`;

const Tri = styled.p`
font-size: 20px;
color: ${props => props.theme.grey09ST};
border-bottom: 1px solid ${props => props.theme.grey09ST};
`;

const Link = styled.button`
text-align: left;
text-decoration: none;
border: none;
background-color: transparent;
position: relative;
cursor: pointer;
color: ${props => props.theme.grey06ST};
&.selected {
  color: ${props => props.theme.redST};
  font-weight: 600;
}
&.sub {
  font-size: 14px;
  color: ${props => props.theme.grey02ST};
}
&:hover {
  color: ${props => props.theme.redST};
  text-decoration: none;
  background-color: transparent;
}
&.actif {
  font-weight: 600;
}
`;

const Result = styled.p`
font-family: "Assistant", sans-serif !important;
border-color: ${props => props.theme.grey02ST};
text-transform: uppercase;
`

const Bouton = styled.button`
display: none;
align-items: center;
justify-content: center;
text-decoration: none;
text-align: center;
text-transform: uppercase;
border: none;
background-color: ${props => props.theme.orangeST};
padding: 18px;
position: relative;
cursor: pointer;
color: #fff;
font-weight: 600;
font-size: 15px;
transition: all .2s ease-out;
&>svg {
  font-size: 25px;
  margin-right: 7px;
}
&:hover {
    opacity: 0.8;
}
@media screen and (max-width: 768px) {
  display: flex;
  width: 100%;
}
`;

const Check = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
gap: 10px;
&.sous {
  padding-left: 10px;
}
`;

const LabelStyledFilter = styled.label`
font-weight: 600;
color: ${props => props.theme.redST};
margin-top: 4px;
cursor: pointer;
&.sous {
  font-weight: 400;
  color: ${props => props.theme.grey02ST};
  font-size: 14px;
}
`;

const WrapFilters = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 10px;
`;

const Buts = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 10px;
`;

const Plus = styled.div`
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
border-radius: 50%;
box-shadow: 0 0 5px rgba(0,0,0,0.1);
height: 30px;
width: 30px;
&>svg {
  color: ${(props) => props.theme.orangeST};
  transition: all 0.3s;
}
&:hover {
  background-color: #79b69f87;
  &>svg {
    color: #fff;
  }
}
`;

const Taux = styled.div`
position: absolute;
top: 10px;
right: 10px;
font-size: 17px;
color: #fff;
background-color: ${(props) => props.theme.redST};
font-weight: 700;
border-radius: 50%;
width: 50px;
height: 50px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
box-shadow: 0 0 10px rgba(0,0,0,0.6);
`;

const Old = styled.span`
text-decoration: line-through solid 2px ${(props) => props.theme.redST};
margin-right: 10px;
`

const Remise = styled.img`
position: absolute;
top: 5px;
right: 5px;
`;

export default function ArticlesListeST({data, cats, idCategorie, onNbrArticlesChange}) {
console.log("cats", cats);
const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const mag = useSelector(state => state?.magasin?.magasin);
const [isLoading, setIsLoading] = useState(false); 
const [isLoadingMore, setIsLoadingMore] = useState(false);
const [filterOption, setFilterOption] = useState('');
const [idCat, setIdCat] = useState('');
const [dataProduits, setDataProduits] = useState([]);
const [showFiltersMob, setShowFiltersMob] = useState(false);
const [dataArticles, setDataArticles] = useState([]);
const [dataProduitsNbr, setDataProduitsNbr] = useState(0);
const [dataProduitsNbrFilter, setDataProduitsNbrFilter] = useState(0);
const [txt, setTxt] = useState('');
const [isErrorVisible, setIsErrorVisible] = useState(false);
const [filters, setFilters] = useState([]);
const [selectedTri, setSelectedTri] = useState('');
const [selectedCats, setSelectedCats] = useState([]);
const [limite, setLimite] = useState(20);
const [showAll, setShowAll] = useState(false);
const [openCategories, setOpenCategories] = useState({});
const [isLoadingPhoto, setIsLoadingPhoto] = useState(true);

// useEffect(() => {
//   setIsLoading(true);
//   axios.post("/users/produits", {
//     Limite: 20,
//     OFFSET: 0,
//     IDCategorieWeb: idCategorie,
//     Filtres: [],
//     IDMagasin: mag?.ID,
//   }).then((response) => {
//     setIsLoading(false);
//     setDataArticles(response?.data?.Articles || []);
//     setDataProduitsNbrFilter(response?.data?.Articles?.length);
//     setDataProduitsNbr(response?.data?.ArticlesNbr || 0);
//   }).catch((err) => {
//     setIsLoading(false);
//     console.log("err", err);
//     setTxt("Une erreur est survenue. Veuillez réessayer plus tard.");
//     setIsErrorVisible(true);
//   });

// }, []);


const handleFilterChange = (key, value, parentCat) => {
  if (key === 'Tri') {
    setFilters((prevFilters) => {
      const newFilters = prevFilters.filter(filter => filter.key !== 'Tri');
      return [...newFilters, { key: 'Tri', value }];
    });
    setSelectedTri(value);
  } else if (key === 'Categories') {
    setFilters((prevFilters) => {
      const newFilters = prevFilters.filter(filter => filter.key !== 'Categories' || filter.parentCat !== parentCat);
      const parentCatFilters = prevFilters.find(filter => filter.key === 'Categories' && filter.parentCat === parentCat);
      let newSelectedValues = parentCatFilters ? [...parentCatFilters.value] : [];
      if (newSelectedValues.includes(value)) {
        newSelectedValues = newSelectedValues.filter(item => item !== value);
      } else {
        newSelectedValues.push(value);
      }
      if (newSelectedValues.length > 0) {
        newFilters.push({ key: 'Categories', parentCat, value: newSelectedValues });
      }
      return newFilters;
    });

    setSelectedCats((prevSelectedCats) => {
      const newSelectedCats = { ...prevSelectedCats };
      if (newSelectedCats[parentCat]) {
        if (newSelectedCats[parentCat].includes(value)) {
          newSelectedCats[parentCat] = newSelectedCats[parentCat].filter((item) => item !== value);
          if (newSelectedCats[parentCat].length === 0) {
            delete newSelectedCats[parentCat];
          }
        } else {
          newSelectedCats[parentCat].push(value);
        }
      } else {
        newSelectedCats[parentCat] = [value];
      }
      return newSelectedCats;
    });
  }
};

console.log("filters", filters);

const clearFilters = () => {
  console.log("clearFilterscccc", filters);
  setFilters([]);
  console.log("clearFilters11111", filters);
  setSelectedTri('');
  setSelectedCats({});
};

useEffect(() => {
  fetchProducts();
}, [filters]);

const fetchProducts = async () => {
  setIsLoading(true);
  const response = await axios.post("/users/produits", {
    Limite: 20,
    OFFSET: 0,
    IDCategorieWeb: idCategorie,
    Filtres: filters,
    IDMagasin: mag?.ID,
  });
  const articles = response?.data?.Articles || [];
  setDataArticles(articles);
  setDataProduitsNbrFilter(articles?.length);
  setDataProduitsNbr(response?.data?.ArticlesNbr || 0);
  setIsLoading(false);
  if (onNbrArticlesChange) {
    onNbrArticlesChange(articles?.length);
  }
};

const handleMoreProds = async (event, currentLength, increment) => {
  console.log("currentLength", currentLength);
  setLimite(currentLength);
  console.log("limite", limite);
  setIsLoadingMore(true);
  try {
    const response = await axios.post("/users/produits", { Limite: increment, OFFSET: currentLength, IDCategorieWeb: idCategorie, Filtres: filters, IDMagasin: mag?.ID });
    const newProds = response.data?.Articles || [];
    setDataArticles(prevProds => [...prevProds, ...newProds]);
    setDataProduitsNbr(response.data?.ArticlesNbr);
    setDataProduitsNbrFilter(prevProds => prevProds + newProds.length);
    setIsLoadingMore(false);
    return newProds;
  } catch (err) {
    console.log("err", err);
    setTxt("Une erreur est survenue. Veuillez réessayer plus tard.");
    setIsErrorVisible(true);
    setIsLoadingMore(false);
    return [];
  }
};


const toggleSider = () => {
  setShowFiltersMob(prevState => !prevState);
};

const handleShowAll = () => {
  setShowAll(true);
};

const handleToggle = (id) => {
  setOpenCategories((prev) => ({
    ...prev,
    [id]: !prev[id],
  }));
};

const getBadgeProps = (item) => {
  if (item?.ArticleSemaine) {
      return { text: "Article de la semaine", color: "#79b69f" };
  } else if (item?.Abajourable) {
      return { text: "Abajourable", color: "#da46da" };
  } else if (item?.Nouveaute) {
      return { text: "Nouveauté", color: "#07af5b" };
  } else if (item?.PromoMois) {
      return { text: "Promo du mois", color: "#5089d7" };
  } else if (item?.PromoWeb) {
      return { text: "Promo Web", color: "#f05b32" }; 
  }
  return { text: "", color: "" };
};

console.log("selectedTri", selectedTri);  

  return (
    <>
    <Helmet>
   <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ItemList",
            itemListElement:dataArticles&& dataArticles?.map((Art, i) => ({
              "@type": "ListItem",
              position: Art?.Meta?.Meta?.Position,
              item: {
                "@type": "Product",
                name: Art?.Meta?.Meta?.Titre,
                image: Art?.Meta?.Meta?.URLImage
                  ? "https://www.selftissus.fr" +
                  Art?.Meta?.Meta?.URLImage
                  : "/logo.svg",
                description: Art?.Meta?.Meta?.Description,
                sku: Art?.Meta?.Meta?.CodeSKU,
                brand: {
                  "@type": "Brand",
                  name: Art?.Meta?.Meta?.Brand,
                },
                offers: {
                  "@type": "Offer",
                  url:
                    "https://www.selftissus.fr" + Art?.Meta?.Meta?.URL,
                  priceCurrency: "EUR",
                  price: Art?.Meta?.Meta?.Tarif,
                  priceValidUntil: Art?.Meta?.Meta?.TarifExpiration,
                  itemCondition: "https://schema.org/NewCondition",
                  availability: "https://schema.org/InStock",
                },
                aggregateRating: {
                  "@type": "AggregateRating",
                  ratingValue: Art?.Meta?.Meta?.RatingValue,
                  ratingCount: Art?.Meta?.Meta?.RatingCountp,
                  bestRating: Art?.Meta?.Meta?.BestRating,
                },
              },
            })),
          })}
        </script>
        </Helmet>
      <Section>
         <WrapperProds>
         {isErrorVisible && <PopErrorST message={txt} titre={"Informations presonnelles"} onClick={() => { setIsErrorVisible(false);}} />}
          <FilterProds>
          <ul className="none">
              <Tri>Trier par</Tri>
              <li>
                <Link onClick={() => { handleFilterChange("Tri", 'PrixC'); setSelectedTri('PrixC'); }}
                style={{ color: selectedTri === 'PrixC' ? '#DF5A4E' : '#121212bf' }}>
                  Prix croissant
                </Link>
              </li>
              <li>
                <Link onClick={() => { handleFilterChange("Tri", 'PrixD'); setSelectedTri('PrixD'); }}
                style={{ color: selectedTri === 'PrixD' ? '#DF5A4E' : '#121212bf' }}>
                  Prix décroissant
                </Link>
              </li>
              <li>
                <Link onClick={() => { handleFilterChange("Tri", 'A'); setSelectedTri('A'); }}
                style={{ color: selectedTri === 'A' ? '#DF5A4E' : '#121212bf' }}>
                  De A à Z
                </Link>
              </li>
              <li>
                <Link onClick={() => { handleFilterChange("Tri", 'Z'); setSelectedTri('Z'); }}
                style={{ color: selectedTri === 'Z' ? '#DF5A4E' : '#121212bf' }}>
                  Z à A
                </Link>
              </li>
            </ul>
          {cats?.length>0 && 
          <ul className="none">
            <Tri>Filtrer par</Tri>
            {
              cats.map((cat, i) => (
                <WrapFilters key={cat?.ID}>
                <Check>
                  <LabelStyledFilter key={cat?.ID} onClick={() => handleToggle(cat?.ID)}>
                    {cat?.Libelle}
                    {openCategories[cat?.ID] ? <FaCaretUp /> : <FaCaretDown />}
                  </LabelStyledFilter>
                </Check>
                <FadeStyled className="noPadding" in={openCategories[cat?.ID]}>
                  {(!cat?.SousCategorie || cat?.SousCategorie.length === 0) && openCategories[cat?.ID] && (
                    <Check key={cat?.ID}>
                      <InputStyled
                        className="sous"
                        type="checkbox"
                        id={cat?.ID}
                        name={cat?.Libelle}
                        value={cat?.ID}
                        onChange={(event) => handleFilterChange("Categories", event.target.value, cat?.ID)}
                        checked={selectedCats[cat?.ID]?.includes(cat?.ID)}
                      />
                      <LabelStyledFilter htmlFor={cat?.ID} className="sous" key={cat?.ID}>{cat?.Libelle}</LabelStyledFilter>
                    </Check>
                  )}
                  {openCategories[cat?.ID] && cat?.SousCategorie?.map((subCat, j) => (
                    <Check key={`${cat?.ID}-${j}`} className="sous">
                      <InputStyled
                        className="sous"
                        type="checkbox"
                        id={subCat?.ID}
                        name={subCat?.Libelle}
                        value={subCat?.ID}
                        onChange={(event) => handleFilterChange("Categories", event.target.value, cat?.ID)}
                        checked={selectedCats[cat?.ID]?.includes(subCat?.ID)}
                      />
                      <LabelStyledFilter htmlFor={subCat?.ID} className="sous" key={subCat?.ID}>{subCat?.Libelle}</LabelStyledFilter>
                    </Check>
                  ))}
                </FadeStyled>
              </WrapFilters>
                
              ))
            }
          </ul>}
          {filters?.length>0 && (
              <Buts>
                {/* <BoutonST
                  className={"small"}
                  lib={"Appliquer les filtres"}
                  background={props => props.theme.orangeST}
                  onClick={fetchProducts}
                /> */}
                <BoutonST
                  className={"small"}
                  lib={"Effacer la sélection"}
                  background={props => props.theme.orangeST}
                  onClick={clearFilters}
                />
              </Buts>
            )}
          </FilterProds>
          <Prods>
          {isLoading && <SpinnerST />} 
          {isLoadingMore && <SpinnerST />}  
            <FilterProds2>
            <FormStyled>
              <InputStyled
                  className="mb-3 min"
                  type="select"
                  style={{padding:"0.5rem 1.05rem",width:"auto"}}
                  onChange={(event) => handleFilterChange('Tri', event.target.value)}
                >
                  <option value="">Trier par</option>
                  <option value="PrixC" selected={selectedTri === "PrixC"}>Prix croissant</option>
                  <option value="PrixD" selected={selectedTri === "PrixD"}>Prix décroissant</option>
                  <option value="A" selected={selectedTri === "A"}>A à Z</option>
                  <option value="Z" selected={selectedTri === "Z"}>Z à A</option>
                </InputStyled>
              </FormStyled>
              <Result>{dataProduitsNbrFilter} / {dataProduitsNbr} produits</Result>
              {cats?.length>0 && <Bouton onClick={toggleSider} className={showFiltersMob ? 'on' : 'off'}>
              {showFiltersMob ? <FaCaretUp className="minus" /> : <FaCaretDown className="plus" />}
                Filtres</Bouton>}
            </FilterProds2>
            {showFiltersMob &&
              <SiderStyled width={"100%"}>
              {cats?.length>0 && 
                   <ul className="none">
                   <Tri>Filtrer par</Tri>
                   {
                     cats.map((cat, i) => (
                       <WrapFilters key={cat?.ID}>
                        <Check>
                         <LabelStyledFilter key={cat?.ID} onClick={() => handleToggle(cat?.ID)}>
                           {cat?.Libelle}
                           {openCategories[cat?.ID]  ? <FaCaretUp/> : <FaCaretDown/>}
                         </LabelStyledFilter>
                         </Check>
                         <FadeStyled className="noPadding" in={openCategories[cat?.ID]}>
                          {(!cat?.SousCategorie || cat?.SousCategorie.length === 0) && openCategories[cat?.ID] && (
                            <Check key={cat?.ID}>
                              <InputStyled
                                className="sous"
                                type="checkbox"
                                id={cat?.ID}
                                name={cat?.Libelle}
                                value={cat?.ID}
                                onChange={(event) => handleFilterChange("Categories", event.target.value, cat?.ID)}
                                checked={selectedCats[cat?.ID]?.includes(cat?.ID)}
                              />
                              <LabelStyledFilter htmlFor={cat?.ID} className="sous" key={cat?.ID}>{cat?.Libelle}</LabelStyledFilter>
                            </Check>
                          )}
                          {openCategories[cat?.ID] && cat?.SousCategorie?.map((subCat, j) => (
                            <Check key={`${cat?.ID}-${j}`} className="sous">
                              <InputStyled
                                className="sous"
                                type="checkbox"
                                id={subCat?.ID}
                                name={subCat?.Libelle}
                                value={subCat?.ID}
                                onChange={(event) => handleFilterChange("Categories", event.target.value, cat?.ID)}
                                checked={selectedCats[cat?.ID]?.includes(subCat?.ID)}
                              />
                              <LabelStyledFilter htmlFor={subCat?.ID} className="sous" key={subCat?.ID}>{subCat?.Libelle}</LabelStyledFilter>
                            </Check>
                          ))}
                        </FadeStyled>
                       </WrapFilters>
                       
                     ))
                   }
                 </ul>}
                    {filters?.length>0 && (
                        <Buts>
                          <BoutonST className={"small"} lib={"Effacer les filtres"} background={props => props.theme.orangeST} onClick={() => clearFilters()} />
                        </Buts> 
                        )
                      }
                </SiderStyled>
              }
              {(dataArticles?.length === 0 && !isLoading) && <p>Aucun produit trouvé</p>}
          {
            dataArticles && dataArticles?.map((item, i) => {
              console.log("item", item?.PrixRemise===item?.Prix?.toString())
              const imageUrl = item?.image?.url ? item?.image?.url : picDefault;
              const imageAlt = item?.image?.alt || item?.libArticle;
              const { text, color } = getBadgeProps(item);
              return (
                <Badge.Ribbon style={{display:(text !== "") ? "block" : "none"}}
                text={text} color={color} placement={"start"} key={i}
                >
                {isLoadingPhoto && <SpinnerInlineMiniST />}
                <CardStyled
                  onClick={() => window.location.href = item?.slug || '#'}
                  key={i}
                  hoverable
                  className="cardBlog"
                  cover={
                    <Img
                    alt={imageAlt}
                    src={imageUrl}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = picDefault;
                      }}
                      loading="lazy"
                      onLoad={() => setIsLoadingPhoto(false)}
                    />
                  }
                >
                  <Titre><h2 className="initial m0 grey fs17">{ReactHtmlParser(item?.libArticle)}</h2></Titre>
                  <p className="uppercase fw600">
                  {(item?.PrixRemise && item?.PrixRemise !== item?.Prix && item?.PrixRemise < item?.Prix ) && <Old>{item?.Prix?.toFixed(2) + "€"}</Old>}
                  {item?.PrixRemise ? <span className="center uppercase orange fw600">{item?.PrixRemise?.toFixed(2)+" €"}</span> : <span className="center uppercase orange fw600">{item?.Prix?.toFixed(2)+" €"}</span>}</p>
                  <Remise src={img30} alt="30%" style={{display: item?.PrixRemiseTaux === "30 %" ? "block" : "none"}} />
                  <Remise src={img50} alt="50%" style={{display: item?.PrixRemiseTaux === "50 %" ? "block" : "none"}} />
                  <Remise src={img70} alt="70%" style={{display: item?.PrixRemiseTaux === "70 %" ? "block" : "none"}} />
                    {/* <span className="center uppercase orange fw600">{item?.Prix?.toFixed(2)+" €"}</span> */}
                    {/* {item?.PrixRemiseTaux && item?.PrixRemiseTaux !=="0 %" &&<Taux>{"-"+item?.PrixRemiseTaux}</Taux>} */}
                    
                </CardStyled>
                </Badge.Ribbon>
              );
            })
          }
          {dataArticles?.length < dataProduitsNbr ? (
            <div style={{width: "100%"}}>
            <BoutonTransparentBtnST margin={"0 auto"} display={"block"}
            onClick={(event) => handleMoreProds(event, dataArticles?.length, 20)}
            lib="Voir plus d'articles"
            />
            </div>
          ) : null}
          </Prods>  
          
        </WrapperProds>
        
      </Section>
      </>
  );
}