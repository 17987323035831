import React, {useState,useEffect} from "react";
import { navigate } from '@reach/router';
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useGeolocated } from "react-geolocated";
import  {Label} from "reactstrap";
import { FormConnect,InputStyled } from "../DesignSystem/SelfTissus/Forms";
import SpinnerST from '../DesignSystem/SpinnersST/Spinner';
import PopErrorST from "../PopupsST/PopError";
import { AiOutlineSearch } from "react-icons/ai";
import BoutonLocST from "../DesignSystem/SelfTissus/BoutonLoc";
import { BoutonVertSearch } from "../DesignSystem/Commons/Buttons";
import { FaLocationCrosshairs } from "react-icons/fa6";
import { AiOutlineArrowRight } from "react-icons/ai";
import BoutonST from "../DesignSystem/SelfTissus/Bouton";
import SpinnerInlineMiniST from "../DesignSystem/SpinnersST/SpinnerInlineMini";

const Wrap = styled.div`
width: 100%;
display: flex;
align-items: center;
flex-direction: column;
gap: 10px;
`

const Geo = styled.div`
position: relative;
font-family: "Assistant", sans-serif !important;
padding: 1rem 1.05rem;
border-radius: 0;
border: #A29995 1px solid;
background-color: #fff;
`

const Villes = styled.div`
position: relative;
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
gap: 10px;

`
const VilleWrapper = styled.button`
min-width: 250px;
max-width: 250px;
min-height: 120px;
max-height: 160px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 5px;
background-color: #fff;
padding: 10px;
border-radius: 5px;
border: 1px solid #A29995;
transition: all 0.3s;
&.pop {
  min-width: 200px;
  max-width: 200px;
  min-height: 95px;
}
&.selected, .id-2.selected {
  background-color: #e1dcdc !important;
  border-color: ${(props) => props.theme.greenST} !important;
  border-width: 2px !important;
}
&.id-2 {
  order: 1;
  background-color: #79b69f8c;
}
&:hover {
  background-color: #F9F9F9;
  border-color: ${(props) => props.theme.orangeST};
}
@media screen and (max-width: 786px) {
  min-width: 100%;
  max-width: 100%;
}
`
const Ville = styled.div`
font-weight: 600;
&.pop {
  font-weight: 600;
  font-size: 14px;
}
`
const Distance = styled.div`
font-weight: 600;
color: ${(props) => props.theme.grey02ST};
`

const Selected = styled.button`
width: 100%;
background: transparent;
border: none;
color: ${props => props.theme.greenST};
font-weight: bold;
margin-bottom: 10px;
font-size: 20px;
&>span {
    display: block;
}
&:hover {
    color: ${props => props.theme.orangeST};
}
`

const Recap = styled.div` 
width: 100%;
`

const Stock = styled.div`
position: relative;
font-weight: 600;
color: ${(props) => props.theme.redST};
&.green {
  color: ${(props) => props.theme.greenST};
}
`


export default function FormLocalisationST({className,onClick, mode, mode2, panier}) {
  
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingStock, setIsLoadingStock] = useState(false);
    const [isLoadingMags, setIsLoadingMags] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [magasins, setMagasins] = useState([]);
    const [selectedMagasinName, setSelectedMagasinName] = useState('');
    const [coordinatesList, setCoordinatesList] = useState([]);
    const [txt, setTxt] = useState('');
    const [city, setCity] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [coordinates, setCoordinates] = useState({ latitude: 0, longitude: 0 });
    const [sortedMagasins, setSortedMagasins] = useState([]);
    const [selectedMagasinObj, setSelectedMagasinObj] = useState(null);
    const dispatch = useDispatch();
    const mag = useSelector(state => state?.magasin);
    const date = useSelector(state => state?.date?.date);
    const heure = useSelector(state => state?.heure?.heure);
    const [selectedMagasin, setSelectedMagasin] = useState('');
    const [hideList, setHideList] = useState(false);
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

    const [stockAvailability, setStockAvailability] = useState(
      magasins?.reduce((acc, magasin) => {
        acc[magasin.uiD] = null; // Initially set to null (Vérifier le stock)
        return acc;
      }, {})
    );
    useEffect(() => {
      setIsLoadingMags(true);
      axios.post(`/users/magasinliste`,{Limite : 20,OFFSET : "" })
      .then((response) => {
        const filteredMagasins = response?.data?.Magasin.filter(magasin => magasin.uiD !== "50ec9764-679e-4acf-8945-61a97ffcb8d5" && magasin.uiD !== "977f6921-ed0a-44e7-9425-742d52bd62ea");
        setMagasins(filteredMagasins);
        setIsLoadingMags(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoadingMags(false);
      });
    }, []);

    const verifyStock = async (magasinID) => {
      setIsLoadingStock(true);
      try {
        const stockRequests = panier?.items
          ?.filter(item => item.mode === "article")
          ?.map(item =>
            axios.post(`/users/stock`, { IDArticle: item.ID, IDMagasin: magasinID })
          );
    
        const responses = await Promise.all(stockRequests);
    
        const stockData = responses.map((response) => ({
          IDArticle: response.data?.IDArticle,
          EnStock: response.data?.EnStock,
        }));
    
        // Check if any item's stock is 0
        const isAnyOutOfStock = stockData.some((item) => item.EnStock === 0);
        // Update stock availability state
        setStockAvailability((prev) => ({
          ...prev,
          [magasinID]: !isAnyOutOfStock,
        }));
        setIsLoadingStock(false);
        return !isAnyOutOfStock;
        
      } catch (err) {
        console.error("Error verifying stock", err);
        setStockAvailability((prev) => ({
          ...prev,
          [magasinID]: false,
        }));
        setIsLoadingStock(false);
        return false;
      }
    };

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
      const toRad = (value) => (value * Math.PI) / 180;
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = toRad(lat2 - lat1);
      const dLon = toRad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c; // Distance in kilometers
    };

    const handleButtonClick = () => {
      
      if (selectedMagasin) {
        console.log("selectedMagasin", selectedMagasin);
          const selectedMagasinObj = magasins.find(magasin => magasin.uiD === selectedMagasin);
          if (selectedMagasinObj) {
            console.log("selectedMagasinObj", selectedMagasinObj);
            setSelectedMagasinObj(selectedMagasinObj);
            dispatch({
                type: "SET_MAGASIN",
                payload: {
                    ID: selectedMagasinObj.uiD,
                    Nom: selectedMagasinObj.Nom
                }
            });
              dispatch({
                type: "SET_MODELIVR",
                payload: {
                  modeLivraison: 2
                }
              });
              dispatch({
                type: "SET_ADRESSE",
                payload: {
                  IDAdresse: selectedMagasinObj.uiD,
                },
              });
            if (mode2 === "panier") {
              dispatch({
                type: "SET_ADRESSE",
                payload: {
                  IDAdresse: selectedMagasinObj.uiD,
                },
              });
              const element = document.getElementById("mode");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }
           
        } else {
            dispatch({
                type: "SET_MAGASIN",
                payload: {
                    ID: "50ec9764-679e-4acf-8945-61a97ffcb8d5",
                    Nom: "WEB"
                }
            });
        }
        setIsErrorVisible(true);
        setTxt(`Magasin sélectionné:<br> ${selectedMagasinObj.Nom}`);
        dispatch({
          type: "DELETE_DATE",
        });
        dispatch({
          type: "DELETE_HEURE",
        });
        if (mode === "pop") {
          onClick();
          navigate(1)
        }
      }
  };
  const {
    coords,
    getPosition,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
      precision: 10,
      timeout: 2000, 
      maximumAge: 0
    },
    userDecisionTimeout: 2000,
    watchLocationPermissionChange: true,
    suppressLocationOnMount: true,
  });
  
  const getLoc = async () => {
    setIsLoading(true);
    try {
      if (!isGeolocationAvailable) {
        throw new Error("Votre navigateur ne supporte pas la géolocalisation");
      }
  
      if (!isGeolocationEnabled) {
        throw new Error("Veuillez autoriser la géolocalisation dans votre navigateur");
      }
  
      const position = await new Promise((resolve, reject) => {
        // First check if we already have coords
        if (coords?.latitude && coords?.longitude) {
          resolve({
            latitude: coords.latitude,
            longitude: coords.longitude
          });
          return;
        }
  
        // If not, request position
        getPosition();
        
        let attempts = 0;
        const maxAttempts = 20; // 
        
        const checkPosition = setInterval(() => {
          if (coords?.latitude && coords?.longitude) {
            clearInterval(checkPosition);
            resolve({
              latitude: coords.latitude,
              longitude: coords.longitude
            });
            return;
          }
  
          if (positionError) {
            clearInterval(checkPosition);
            reject(new Error(positionError.message));
            return;
          }
  
          attempts++;
          if (attempts >= maxAttempts) {
            clearInterval(checkPosition);
            reject(new Error("La géolocalisation prend trop de temps. Veuillez réessayer."));
            return;
          }
        }, 100);
  
        // Cleanup
        return () => clearInterval(checkPosition);
      });
  
      const response = await axios.get(
        `https://api-adresse.data.gouv.fr/reverse/?lon=${position.longitude}&lat=${position.latitude}`
      );
      
      if (!response?.data?.features?.[0]) {
        throw new Error("Aucune adresse trouvée pour ces coordonnées");
      }
  
      const result = response.data.features[0];
      const properties = result?.properties;
      const cityName = properties?.city || properties?.town || properties?.village;
      
      if (!cityName) {
        throw new Error("Impossible de déterminer la ville");
      }
  
      setCity(cityName);
      await handleSearch(cityName);
  
    } catch (error) {
      console.error("Geolocation error:", error);
      setIsErrorVisible(true);
      setTxt(error.message || "Erreur lors de la géolocalisation. Veuillez réessayer.");
    } finally {
      setIsLoading(false);
    }
  };


          const searchCityCoordinates = (searchTerm, setCoordinates) => {
            // console.log("Function called with searchTerm:", searchTerm);
            if (!searchTerm || !searchTerm.trim()) {
              console.error("Search term input is empty");
              return;
            }
          
            let query = "";
            query = `q=${searchTerm}`;
            console.log("Query formed:", query);
          
            console.log("Starting API call...");
            axios
              .get(`https://api-adresse.data.gouv.fr/search/?${query}`)
              .then((response) => {
                // console.log("API response:", response); // Log the entire response
                if (response.data.features.length > 0) {
                  console.log("Search results:", response.data.features); // Log results for debugging
                  const result = response.data.features[0];
                  console.log("Search result:", result); // Log result for debugging
                  const coordinates = result.geometry.coordinates || [0, 0];
                  setCoordinates({ latitude: coordinates[1], longitude: coordinates[0] });
                  // console.log("City coordinates:", coordinates);
                } else {
                  console.error("No results found for the given search term.");
                }
              })
              .catch((error) => {
                console.error("Error during API call:", error);
                if (error.response) {
                  console.error("Response data:", error.response.data);
                } else if (error.request) {
                  console.error("Request data:", error.request);
                } else {
                  console.error("Error message:", error.message);
                }
              });
          };

          const fetchCoordinates = async (cityName) => {
            console.log("City name111:", cityName);
            const isValidCityName = /^[a-zA-Z0-9À-ÿ][a-zA-Z0-9À-ÿ\s-]{2,199}$/.test(cityName);
            console.log("isValidCityName:", isValidCityName);
            if (!isValidCityName) {
              setIsErrorVisible(true);
              setTxt("Le texte doit contenir entre 3 et 200 caractères et commencer par un chiffre ou une lettre");
            } else {
              setCity(cityName);
            }
            console.log("City name2222:", city);
            console.log("Fetching coordinates for city:", cityName);
            try {
              const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${cityName}`);
              if (response.data.features.length > 0) {
                const result = response.data.features[0];
                const coordinates = result.geometry.coordinates || [0, 0];
                return { latitude: coordinates[1], longitude: coordinates[0] };
              } else {
                console.error(`No results found for city : ${cityName}`);
                setIsErrorVisible(true);
                setTxt(`Aucun résultat trouvé pour la ville : ${cityName}`);
                return { latitude: 0, longitude: 0 };
              }
            } catch (error) {
              console.error(`Error fetching coordinates for city : ${cityName}`, error);
              console.error(`Error fetching coordinates for city : ${cityName}`, error);
              return { latitude: 0, longitude: 0 };
            }
          };

           useEffect(() => {
            const fetchAllCoordinates = async () => {
              const coordinatesPromises = magasins.map(async (magasin) => {
                const coords = await fetchCoordinates(magasin.Ville);
                const distance = calculateDistance(coordinates.latitude, coordinates.longitude, coords.latitude, coords.longitude);
                return { ...magasin, coordinates: coords, distance };
              });
              const results = await Promise.all(coordinatesPromises);
              results.sort((a, b) => a.distance - b.distance);
              // setSortedMagasins(results);
              setSortedMagasins(results.slice(0, 1)); // Keep only the closest magasin
            };

            if (coordinates.latitude !== 0 && coordinates.longitude !== 0) {
              fetchAllCoordinates();
            }
          }, [coordinates, magasins]);

          const handleSearch = async (cityName) => {
            // console.log("Searching for city:", cityName);
            const coords = await fetchCoordinates(cityName);
            setCoordinates(coords);
          };

          useEffect(() => {
            if (selectedMagasin) {
              verifyStock(selectedMagasin);
              handleButtonClick();
              setSelectedMagasin("");
              setHideList(true);
              window.scrollTo(0, 0);
            }
          }, [selectedMagasin]);
        return(
        <>
        {isErrorVisible && <PopErrorST classNameBut={"white"} message={txt} titre={"Magasin SELF TISSUS"} onClick={() => setIsErrorVisible(false)}/>}
        {isLoading && <SpinnerST />}
        {!hideList ? (
        <Wrap>
          <p className="green">
            Choissisez un magasin près de chez vous
          </p>
          <Villes>
            {isLoadingMags && <SpinnerInlineMiniST/>}
            {magasins?.map((magasin, index) => (
              <VilleWrapper
                key={index}
                onClick={() => {setSelectedMagasin(magasin?.uiD);verifyStock(magasin?.uiD);}}
                className={[`id-${magasin?.ID} ${selectedMagasin === magasin?.uiD ? 'selected' : ''} ${mode}`]}
              >
                <Ville className={mode ==="pop" ? "pop" : ""}>
                  {magasin?.NomCommercial}
                  <br />
                  {"(" + magasin?.Ville + ")"}
                </Ville>
                {!mode?.includes("pop") && !mode?.includes("locGenerale") ? (
                selectedMagasin === magasin?.uiD && (
                  stockAvailability[magasin?.uiD] === false ? (
                    <>
                      {isLoadingStock ? (<Stock><SpinnerInlineMiniST /></Stock>) :
                      (<Stock>Certains articles ne sont pas disponibles</Stock>)}
                    </>
                ) : stockAvailability[magasin.uiD] === true ? <Stock className="green">En stock</Stock> : null)
              ) : null}
              </VilleWrapper>
            ))}
          </Villes>
          {/* <BoutonST
            lib={"Appliquer"}
            onClick={() => {
              if (selectedMagasin) {
                handleButtonClick();
                setHideList(true);
              }
            }}
            background={props => props.theme.orangeST}
            width={"300px"}
          /> */}
          <p className="green mt50">
            Choissisez un autre magasin
          </p>
          <FormConnect className={[className]}>
            <InputStyled
              id="search"
              name="search"
              placeholder={"Ville ou code postal"}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <BoutonVertSearch onClick={() => handleSearch(searchTerm)}>
              <span><AiOutlineSearch /></span>
            </BoutonVertSearch>
          </FormConnect>
          <FormConnect className={[className]}>
            <Geo>Me géolocaliser <AiOutlineArrowRight /></Geo>
            <BoutonVertSearch onClick={getLoc}>
              <span><FaLocationCrosshairs /></span>
            </BoutonVertSearch>
          </FormConnect>

          {!isGeolocationAvailable ? (
            <div className="red">Votre navigateur ne supporte pas la géolocalisation</div>
          ) : !isGeolocationEnabled ? (
            <div className="red"> Veuillez autoriser la géolocalisation sur votre navigateur</div>
          ) : coords ? (
            <div>
              {/* <p>Latitude: {coords.latitude}</p>
              <p>Longitude: {coords.longitude}</p> */}
              {/* <p>Votre ville: {city}</p> */}
            </div>
          ) : (
            <div></div>
          )}
          {positionError && (
            <p>
              {positionError.code === 1
                ? "L'utilisateur a refusé la géolocalisation"
                : positionError.message}
            </p>
          )}

          {sortedMagasins?.length>0 && 
          <><p className="orange">Magasin le plus proche : </p>
          <Villes className="geo">
            
            {sortedMagasins.map((magasin, index) => (
              <VilleWrapper
                key={index}
                onClick={() => {setSelectedMagasin(magasin?.uiD);verifyStock(magasin?.uiD);}}
                className={`id-${magasin.ID} ${selectedMagasin === magasin.uiD ? 'selected' : ''} ${mode}`}
              >
                <Ville className="geo">
                  {magasin.NomCommercial}
                  <br />
                  {"(" + magasin.Ville + ")"}
                </Ville>
                <Distance>Distance : {magasin.distance.toFixed(2)} km</Distance>
                {!mode.includes("pop") && !mode.includes("locGenerale") ? (
                  selectedMagasin === magasin?.uiD && (
                    stockAvailability[magasin?.uiD] === false ? (
                      <>
                        {isLoadingStock ? (
                          <Stock><SpinnerInlineMiniST /></Stock>
                        ) : (
                          <Stock>Certains articles ne sont pas disponibles</Stock>
                        )}
                      </>
                    ) : stockAvailability[magasin.uiD] === true ? (
                      <Stock className="green">En stock</Stock>
                    ) : null
                  )
                ) : null}
              </VilleWrapper>
            ))}
          </Villes></>}

          

          <BoutonST
            lib={"Appliquer"}
            onClick={() => {
              if (selectedMagasin) {
                handleButtonClick();
                setHideList(true);
              }
            }}
            background={props => props.theme.orangeST}
            width={"300px"}
          />
        </Wrap>
      ) : (
        <Recap>
        <Selected onClick={() => setHideList(!hideList)}>
          <span>{selectedMagasinObj?.NomCommercial}</span>
          <span>{selectedMagasinObj?.Adresse1}</span>
          <span>{selectedMagasinObj?.Adresse2}</span>
          <span>{selectedMagasinObj?.CP} {selectedMagasinObj?.Ville}</span>
        </Selected>
        {/* {(!date || !heure) ? (
          <> */}
        {/* <p className="orange center">Choisissez une date et une heure pour retirer votre commande</p>

          <FormConnect floating>
            <InputStyled type="date" min={today} id="date" name="date"
            onChange={(e) => {
              dispatch({
                type: "SET_DATE",
                payload: {
                  date: e.target.value,
                },
              });
            }}
            />
            <Label for="date">Date</Label>
          </FormConnect>

          <FormConnect floating>
            <InputStyled type="select" id="heure" name="heure" 
            onChange={(e) => {
              dispatch({
                type: "SET_HEURE",
                payload: {
                  heure: e.target.value,
                },
              });
            }}
            >
            <option value={0}>Choisir une heure</option>
            <option value={"10:00"}>10:00</option>
            <option value={"11:00"}>11:00</option>
            <option value={"14:00"}>14:00</option>
            <option value={"15:00"}>15:00</option>
            <option value={"16:00"}>16:00</option>
            <option value={"17:00"}>17:00</option>
            <option value={"18:00"}>18:00</option>
            </InputStyled>
            <Label for="heure">Heure</Label>
          </FormConnect> </>) :
          (<p className="orange center">Le {formatDate(date?.date)} à {heure?.heure}</p>)
          } */}
        {/* <BoutonST lib={"Fermer"} onClick={onClick} width={"300px"} /> */}
        </Recap>
      )}
    </>
  );
};

