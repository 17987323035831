import React from "react";
import styled from 'styled-components';
import { Section } from "../DesignSystem/SelfTissus/ContainersST";
import picDefault from "../../assets/images/image.png";
import BoutonNoirLnkST from "../DesignSystem/SelfTissus/BoutonNoirLnk";
import ReactHtmlParser from 'react-html-parser';

const Img = styled.img`
flex-basis: 40%;
max-height: 530px;
box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
@media screen and (max-width: 786px) {
    flex-basis: 100%;
    max-width: 100%;
    /* max-height: 300px; */
}
`;

const Wrap = styled.div`
width: 100%;
display: flex;
align-items: center;
gap: 50px;
justify-content: center;
@media screen and (max-width: 992px) {
    flex-wrap: wrap;
}
`;

const Txt = styled.div`
    flex-basis: 45%;
`;

const Inside = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-content: center;
align-items: center;
justify-content: center;
gap: 30px;
`;


export default function ImgGaucheTxtST({data}) {
    return (
        <>
        <Section>
            <Wrap>
                <Img src={data?.Data[0].image?.url} alt={data?.Data[0].image?.alt}
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = picDefault;
                    }} loading="lazy"
                    />
                <Inside>
                    <Txt>
                        <h2>{ReactHtmlParser(data?.Titre)}</h2>
                        {ReactHtmlParser(data?.Corps)}
                    </Txt>
                    {data?.BTNLib &&<BoutonNoirLnkST href={data?.BTNUrl} lib={data?.BTNLib} target={data?.BTNMode ? "_blank" : "_self"}/>}
                </Inside>
              
            </Wrap>
        </Section>
        </>
    )
}