import React, { useState, lazy, Suspense, useEffect } from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Router } from "@reach/router";
import axios from "axios";
import loadable from "@loadable/component";
import Logo from "../assets/images/Logo.svg";
import TitreTxtBtnST from "../components/BlocsST/TitreTextBtn";
import VideoST from "../components/Video/VideoST";
import SliderProduitsST from "../components/SlidersST/SliderProduits";
import BlogListeST from "../components/Blog/BlogListeSF";
import BlogFicheST from "../components/Blog/BlogFiche";
import BandeauTutosST from "../components/SectionTutos/BandeauTutos";
import { Container } from "../components/DesignSystem/SelfTissus/ContainersST";
import SocialsBigST from "../components/Socials/SocialsBig";
import TitreTextST from "../components/ContenusST/TitreText";
import TitreH2TextST from "../components/ContenusST/TitreH2Text";
import PresentationTitreST from "../components/ContenusST/PresentationTitre";
import TextST from "../components/ContenusST/Text";
import FormLevelST from "../components/FormsST/FormLevel";
import H1_ST from "../components/ContenusST/H1";
import H2_ST from "../components/ContenusST/H2";
import H2LinesST from "../components/ContenusST/H2Lines";
import SliderYouTubeST from "../components/SlidersST/SliderYouTube";
import InteractionsST from "../components/BandeauInteractions/BandeauInteractions";
import SliderProduitsAdminST from "../components/SlidersST/SliderProduitsAdmin";
import ArticleST from "../components/Article/Article";
import SliderRealisationsST from "../components/SlidersST/SliderRealisations";
import NavBlogsST from "../components/NavBar/NavBlogs";
import AteliersListeST from "../components/Atelier/AteliersListe";
import AtelierFicheST from "../components/Atelier/AtelierFiche";
import NotFoundST from "../components/404/NotFound";
import FormContactST from "../components/FormsST/FormContact";
import FaqST from "../components/FAQ/FaqListe";
import CardsST from "../components/ContenusST/Cards";
import ImgGaucheTxtST from "../components/ContenusST/ImgGaucheTxt";
import BlocsImgTxtST from "../components/ContenusST/BlocsImgText";
import MapST from "../components/Magasins/MagasinsMap";
import HeroHomeST from "../components/Hero/HeroSlider_HomeST";
import InfosListeST from "../components/Livraison/InfosListe";
import ImageST from "../components/Images/Image";
import ImageLienST from "../components/Images/ImageLien";
import ImgDroitTxtST from "../components/ContenusST/ImgDroitTxt";
import BoutonNoirLnkST from "../components/DesignSystem/SelfTissus/BoutonNoirLnk";
import EspaceST from "../components/DesignSystem/Espace";
import { PopUp, Wrap, ButFermer } from "../components/DesignSystem/PopUpST";
import FormNewsletterST from "../components/FormsST/FormNewsletter";
import MagasinFicheST from "../components/Magasins/MagasinFiche";
import CategorieIntroST from "../components/Categorie/CategorieIntro";
import FormConnexionST from "../components/FormsST/FormConnexion";
import LocalisationST from "../components/Localisation/Localisation";
import FranchiseST from "../components/Franchise/Franchise";
import MagasinsListeST from "../components/Magasins/MagasinsListe";
import PageLoading from "../components/DesignSystem/PageLoading";
import RealisationsListeST from "../components/Realisations/RealisationsListe";
import TutosListeST from "../components/Realisations/TutosListe";
import Footer1ST from "../components/FooterST/Footer1_ST";
import Footer2ST from "../components/FooterST/Footer2_ST";
import Footer3ST from "../components/FooterST/Footer3_ST";
import Footer4ST from "../components/FooterST/Footer4_ST";
import ProduitsListeST from "../components/Produits/ProduitsListe";
import PanierPaiementValideST from "../components/Panier/PanierPaiementValide";
import PanierPaiementInvalideST from "../components/Panier/PanierPaiementInvalide";
import ArianeST from "../components/ArianeST/Ariane";
import MenuBoutonsST from "../components/MenuBoutons/MenuBoutons";
import { navigate } from "@reach/router";
import BlogListeMiniST from "../components/Blog/BlogListeMiniSF";

export default function Dispatcher() {
  const location = useLocation();
  const [dataPageComp, setDataPageComp] = useState([]);
  const [dataAriane, setDataAriane] = useState([]);
  const [dataPage, setDataPage] = useState([]);
  const [dataSousCategories, setDataSousCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataBandeau1, setDataBandeau1] = useState([]);
  const [showRecommand, setShowRecommand] = useState(true);
  const [showNL, setShowNL] = useState(false);
  const dispatch = useDispatch();
  const mag = useSelector((state) => state.magasin.magasin);

  useEffect(() => {
    // Disable browser's default scroll restoration
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    // Scroll to the top of the page
    window.scrollTo(0, 0);

    // Cleanup function to reset scroll restoration behavior
    return () => {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "auto";
      }
    };
  }, []);

  if (mag === null) {
    dispatch({
      type: "SET_MAGASIN",
      payload: { ID: "50ec9764-679e-4acf-8945-61a97ffcb8d5", Nom: "WEB" },
    });
  }
  const user = useSelector((state) => state.user?.user?.Contact);

  useEffect(() => {
    if (location.pathname === "/paiement-valide") {
      dispatch({
        type: "DELETE_ADRESSE",
      });
      dispatch({
        type: "DELETE_BASKET",
      });
      dispatch({
        type: "DELETE_MODELIVR",
      });
      dispatch({
        type: "DELETE_RELAIS",
      });
      dispatch({
        type: "DELETE_DATE",
      });
      dispatch({
        type: "DELETE_HEURE",
      });
      dispatch({
        type: "SET_MAGASIN",
        payload: {
          ID: "50ec9764-679e-4acf-8945-61a97ffcb8d5",
          Nom: "WEB",
        },
      });
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   const subscribedNL = localStorage.getItem("subscribedNL");
  //   const hasSubscribedNL = subscribedNL === "true";
  //   const hasClosedNL = localStorage.getItem("closedNL");
  //   const closedNLTimestamp = localStorage.getItem("closedNLTimestamp");
  //   const oneHour = 60 * 60 * 1000; // 1 hour in milliseconds

  //   if (hasSubscribedNL || user?.Newsletter) {
  //     setShowNL(false);
  //   } else if (hasClosedNL && closedNLTimestamp) {
  //     const currentTime = new Date().getTime();
  //     const timeElapsed = currentTime - closedNLTimestamp;
  //     if (timeElapsed > oneHour) {
  //       setShowNL(true);
  //       localStorage.removeItem("closedNLTimestamp");
  //     } else {
  //       setShowNL(false);
  //     }
  //   } else {
  //     const timer = setTimeout(() => {
  //       setShowNL(true);
  //     }, 45 * 60 * 1000); // 10 seconds in milliseconds

  //     return () => clearTimeout(timer); // Cleanup the timer on component unmount
  //   }
  // }, [user]);

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(
        `/ysw/dataslug?{"CleCtrl":"TaTaYoYo","act":"DataSlug", "Slug":"${
          location.pathname
        }", "IDCategorieWeb":"${localStorage.getItem("idCategorieWeb")}"}`
      )
      .then((res) => {
        localStorage.setItem("idCategorieWeb", dataPage?.CategorieWeb?.ID);
        // console.log("res.data", res.data);
        setDataPage(res.data ? res.data : []);
        setDataSousCategories(
          res.data?.CategorieWeb && res.data?.CategorieWeb?.FiltreGauche
            ? res.data?.CategorieWeb?.FiltreGauche
            : []
        );
        setDataPageComp(res?.data?.Composants ? res?.data?.Composants : []);
        setDataAriane(res?.data?.FilsAriane ? res?.data?.FilsAriane : []);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error?.response?.status === 302) {
          navigate(error?.response?.data);
        }
      });
  }, [location.pathname]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(`/users/bandeau`, { Limite: "", OFFSET: "", NumBandeau: 1 })
      .then((response) => {
        setDataBandeau1(response?.data);
        // setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        // setIsLoading(false);
      });
  }, []);

  // useEffect(() => {
  //     setIsLoading(true);
  //     axios.post(`/users/filsariane`)
  //       .then((response) => {
  //         console.log("responseFA", response);
  //         // setIsLoading(false);
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //         // setIsLoading(false);
  //       });
  //   }, []);

  console.log("dataPage", dataPage);

  return (
    <div>
        {/* helmet pages */}
      {dataPage?.Page && (
        <Helmet>
          <html lang="fr" />
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <link rel="icon" type="image/svg+xml" href="/favicon.ico" />
          <link rel="alternate icon" href="/favicon.ico" />
          <meta
            name="description"
            content={dataPage?.Page?.Meta?.Meta?.Description}
          />
          <link
            rel="canonical"
            href={`https://www.selftissus.fr${location.pathname}`}
          />
          <title>{dataPage?.Page?.Meta?.Meta?.Titre}</title>
          <meta
            property="og:title"
            content={dataPage?.Page?.Meta?.Meta?.Titre}
            key="ogtitle"
          />
          <meta
            property="og:description"
            content={dataPage?.Page?.Meta?.Meta?.Description}
            key="ogdesc"
          />
          <meta propery="og:site_name" content="Self Tissus" key="ogsitename" />
          <meta
            property="og:image"
            content={
              dataPage?.Page?.Meta?.Meta?.URLImage
                ? "https://www.selftissus.fr" +
                  dataPage?.Page?.Meta?.Meta?.URLImage
                : Logo
            }
          />
          {location && (
            <meta
              property="og:url"
              content={`https://www.selftissus.fr${location.pathname}`}
            />
          )}

          <meta
            name="twitter:title"
            content={dataPage?.Page?.Meta?.Meta?.Titre}
          />
          <meta
            name="twitter:description"
            content={dataPage?.Page?.Meta?.Meta?.Description}
          />
          <meta
            name="twitter:site_name"
            content={dataPage?.Page?.Meta?.Meta?.Titre}
          />
          <meta
            name="twitter:image"
            content={
              "https://www.selftissus.fr" + dataPage?.Page?.Meta?.Meta?.URLImage
                ? dataPage?.Page?.Meta?.Meta?.URLImage
                : Logo
            }
          />
          <meta name="twitter:creator" content={"Self Tissus"} />
          <meta
            name="twitter:url"
            content={`https://www.selftissus.fr${location.pathname}`}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@yogan" />

          <meta
            name="twitter:image:alt"
            content={dataPage?.Page?.Meta?.Meta?.Titre}
          />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": dataPage?.Page?.Meta?.Meta?.Type,
              name: dataPage?.Page?.Meta?.Meta?.Titre,
              description: dataPage?.Page?.Meta?.Meta?.Description,
              url: "https://www.selftissus.fr" + location.pathname,
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              name: "Self Tissus",
              telephone: "+33-298901939",
              url: "https://www.selftissus.fr/",
              description:
                "Site officiel de Self Tissus: Des Tissus de qualité pour tous vos projets de couture, d'ameublement et de décoration. Retrouvez nos tissus au mètre, mercerie et patrons de couture sur notre site en ligne.",
              logo: "https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg",
              image:
                "https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg",
              email: "achats@selftissus.fr",
              openingHours: "Mo-we 09:00-17:30",
              priceRange: "$$",
              sameAs: [
                "https://www.facebook.com/SelfTissus",
                "https://www.pinterest.fr/selftissusfr/",
                "https://www.instagram.com/self_tissus/",
                "https://www.youtube.com/@selftissusfrance",
              ],
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+33-298901939",
                contactType: "customer service",
                hoursAvailable: {
                  "@type": "OpeningHoursSpecification",
                  name: "Mo-We 09:00-17:30",
                },
                availableLanguage: {
                  "@type": "Language",
                  name: "French",
                },
              },
              address: {
                "@type": "PostalAddress",
                addressLocality: "QUIMPER",
                streetAddress: "10 rue Louison Bobet",
                addressRegion: "Finistère",
                postalCode: "29000",
                telephone: "+33-298901939",
                addressCountry: {
                  "@type": "Country",
                  name: "FR",
                },
              },
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              name: "Self Tissus",
              url: "https://www.selftissus.fr" + location.pathname,
              description: dataPage?.Page?.Meta?.Meta?.Description,
              publisher: {
                "@type": "Organization",
                name: "Self Tissus",
                logo: {
                  "@type": "ImageObject",
                  url: "https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg",
                },
              },
              mainEntityOfPage: {
                "@type": "WebPage",
                "@id": "https://www.selftissus.fr" + location.pathname,
              },
            })}
          </script>

          {location.pathname !== "/" && (
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  ...dataAriane
                    ?.slice()
                    .sort(
                      (a, b) => dataAriane?.indexOf(b) - dataAriane?.indexOf(a)
                    )
                    .map((item, index) => ({
                      "@type": "ListItem",
                      position: index + 1,
                      name: item.Lib,
                      item:
                        index === 0
                          ? "https://www.selftissus.fr"
                          : `https://www.selftissus.fr${item.URL}`,
                    })),
                ],
              })}
            </script>
          )}
        </Helmet>
      )}
{/* Helmet Categorie       */}
      {dataPage?.CategorieWeb && (
        <Helmet>
          <html lang="fr" />
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <link rel="icon" type="image/svg+xml" href="/favicon.ico" />
          <link rel="alternate icon" href="/favicon.ico" />
          <meta
            name="description"
            content={dataPage?.CategorieWeb?.Meta?.Meta?.Description}
          />
          <link
            rel="canonical"
            href={`https://www.selftissus.fr${location.pathname}`}
          />
          <title>{dataPage?.CategorieWeb?.Meta?.Meta?.Titre +" || Self Tissus"  }</title>
          <meta
            property="og:title"
            content={dataPage?.CategorieWeb?.Meta?.Meta?.Titre  +" || Self Tissus"}
            key="ogtitle"
          />
          <meta
            property="og:description"
            content={dataPage?.CategorieWeb?.Meta?.Meta?.Description}
            key="ogdesc"
          />
          <meta propery="og:site_name" content="Self Tissus" key="ogsitename" />
          <meta
            property="og:image"
            content={
              dataPage?.CategorieWeb?.Meta?.Meta?.URLImage
                ? "https://www.selftissus.fr" +
                  dataPage?.Page?.Meta?.Meta?.URLImage
                : Logo
            }
          />
          {location && (
            <meta
              property="og:url"
              content={`https://www.selftissus.fr${location.pathname}`}
            />
          )}

          <meta
            name="twitter:title"
            content={dataPage?.CategorieWeb?.Meta?.Meta?.Titre  +" || Self Tissus"}
          />
          <meta
            name="twitter:description"
            content={dataPage?.CategorieWeb?.Meta?.Meta?.Description}
          />
          <meta
            name="twitter:site_name"
            content={dataPage?.CategorieWeb?.Meta?.Meta?.Titre  +" || Self Tissus"}
          />
          <meta
            name="twitter:image"
            content={
              "https://www.selftissus.fr" + dataPage?.CategorieWeb?.Meta?.Meta?.URLImage
                ? dataPage?.CategorieWeb?.Meta?.Meta?.URLImage
                : Logo
            }
          />
          <meta name="twitter:creator" content={"Self Tissus"} />
          <meta
            name="twitter:url"
            content={`https://www.selftissus.fr${location.pathname}`}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@yogan" />

          <meta
            name="twitter:image:alt"
            content={dataPage?.CategorieWeb?.Meta?.Meta?.Titre  +" || Self Tissus"}
          />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": dataPage?.CategorieWeb?.Meta?.Meta?.Type,
              name: dataPage?.CategorieWeb?.Meta?.Meta?.Titre,
              description: dataPage?.CategorieWeb?.Meta?.Meta?.Description,
              url: "https://www.selftissus.fr" + location.pathname,
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              name: "Self Tissus",
              telephone: "+33-298901939",
              url: "https://www.selftissus.fr/",
              description:
                "Site officiel de Self Tissus: Des Tissus de qualité pour tous vos projets de couture, d'ameublement et de décoration. Retrouvez nos tissus au mètre, mercerie et patrons de couture sur notre site en ligne.",
              logo: "https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg",
              image:
                "https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg",
              email: "achats@selftissus.fr",
              openingHours: "Mo-we 09:00-17:30",
              priceRange: "$$",
              sameAs: [
                "https://www.facebook.com/SelfTissus",
                "https://www.pinterest.fr/selftissusfr/",
                "https://www.instagram.com/self_tissus/",
                "https://www.youtube.com/@selftissusfrance",
              ],
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+33-298901939",
                contactType: "customer service",
                hoursAvailable: {
                  "@type": "OpeningHoursSpecification",
                  name: "Mo-We 09:00-17:30",
                },
                availableLanguage: {
                  "@type": "Language",
                  name: "French",
                },
              },
              address: {
                "@type": "PostalAddress",
                addressLocality: "QUIMPER",
                streetAddress: "10 rue Louison Bobet",
                addressRegion: "Finistère",
                postalCode: "29000",
                telephone: "+33-298901939",
                addressCountry: {
                  "@type": "Country",
                  name: "FR",
                },
              },
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              name: "Self Tissus",
              url: "https://www.selftissus.fr" + location.pathname,
              description: dataPage?.CategorieWeb?.Meta?.Meta?.Description,
              publisher: {
                "@type": "Organization",
                name: "Self Tissus",
                logo: {
                  "@type": "ImageObject",
                  url: "https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg",
                },
              },
              mainEntityOfPage: {
                "@type": "WebPage",
                "@id": "https://www.selftissus.fr" + location.pathname,
              },
            })}
          </script>

          {location.pathname !== "/" && (
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  ...dataAriane
                    ?.slice()
                    .sort(
                      (a, b) => dataAriane?.indexOf(b) - dataAriane?.indexOf(a)
                    )
                    .map((item, index) => ({
                      "@type": "ListItem",
                      position: index + 1,
                      name: item.Lib,
                      item:
                        index === 0
                          ? "https://www.selftissus.fr"
                          : `https://www.selftissus.fr${item.URL}`,
                    })),
                ],
              })}
            </script>
          )}
          
        </Helmet>
      )}
{/* Helmet Atelier */}
{dataPage?.Atelier && (
    
    <Helmet>
         <html lang="fr" />
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <link rel="icon" type="image/svg+xml" href="/favicon.ico" />
          <link rel="alternate icon" href="/favicon.ico" />
          <meta
            name="description"
            content={dataPage?.Atelier?.Meta?.Meta?.Description}
          />
          <link
            rel="canonical"
            href={`https://www.selftissus.fr${location.pathname}`}
          />
          <title>{dataPage?.Atelier?.Meta?.Meta?.Titre +" || Self Tissus"  }</title>
          <meta
            property="og:title"
            content={dataPage?.Atelier?.Meta?.Meta?.Titre  +" || Self Tissus"}
            key="ogtitle"
          />
          <meta
            property="og:description"
            content={dataPage?.Atelier?.Meta?.Meta?.Description}
            key="ogdesc"
          />
          <meta propery="og:site_name" content="Self Tissus" key="ogsitename" />
          <meta
            property="og:image"
            content={
              dataPage?.Atelier?.Meta?.Meta?.URLImage
                ? "https://www.selftissus.fr" +
                  dataPage?.Atelier?.Meta?.Meta?.URLImage
                : Logo
            }
          />
          {location && (
            <meta
              property="og:url"
              content={`https://www.selftissus.fr${location.pathname}`}
            />
          )}

          <meta
            name="twitter:title"
            content={dataPage?.Atelier?.Meta?.Meta?.Titre  +" || Self Tissus"}
          />
          <meta
            name="twitter:description"
            content={dataPage?.Atelier?.Meta?.Meta?.Description}
          />
          <meta
            name="twitter:site_name"
            content={dataPage?.Atelier?.Meta?.Meta?.Titre  +" || Self Tissus"}
          />
          <meta
            name="twitter:image"
            content={
              "https://www.selftissus.fr" + dataPage?.Atelier?.Meta?.Meta?.URLImage
                ? dataPage?.Atelier?.Meta?.Meta?.URLImage
                : Logo
            }
          />
          <meta name="twitter:creator" content={"Self Tissus"} />
          <meta
            name="twitter:url"
            content={`https://www.selftissus.fr${location.pathname}`}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@yogan" />

          <meta
            name="twitter:image:alt"
            content={dataPage?.Atelier?.Meta?.Meta?.Titre  +" || Self Tissus"}
          />
          {/* <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": dataPage?.Atelier?.Meta?.Meta?.Type,
              name: dataPage?.Atelier?.Meta?.Meta?.Titre,
              description: dataPage?.Atelier?.Meta?.Meta?.Description,
              url: "https://www.selftissus.fr/" + location.pathname,
            })}
          </script> */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              name: "Self Tissus",
              telephone: "+33-298901939",
              url: "https://www.selftissus.fr/",
              description:
                "Site officiel de Self Tissus: Des Tissus de qualité pour tous vos projets de couture, d'ameublement et de décoration. Retrouvez nos tissus au mètre, mercerie et patrons de couture sur notre site en ligne.",
              logo: "https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg",
              image:
                "https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg",
              email: "achats@selftissus.fr",
              openingHours: "Mo-we 09:00-17:30",
              priceRange: "$$",
              sameAs: [
                "https://www.facebook.com/SelfTissus",
                "https://www.pinterest.fr/selftissusfr/",
                "https://www.instagram.com/self_tissus/",
                "https://www.youtube.com/@selftissusfrance",
              ],
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+33-298901939",
                contactType: "customer service",
                hoursAvailable: {
                  "@type": "OpeningHoursSpecification",
                  name: "Mo-We 09:00-17:30",
                },
                availableLanguage: {
                  "@type": "Language",
                  name: "French",
                },
              },
              address: {
                "@type": "PostalAddress",
                addressLocality: "QUIMPER",
                streetAddress: "10 rue Louison Bobet",
                addressRegion: "Finistère",
                postalCode: "29000",
                telephone: "+33-298901939",
                addressCountry: {
                  "@type": "Country",
                  name: "FR",
                },
              },
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              name: "Self Tissus",
              url: "https://www.selftissus.fr" + location.pathname,
              description: dataPage?.Atelier?.Meta?.Meta?.Description,
              publisher: {
                "@type": "Organization",
                name: "Self Tissus",
                logo: {
                  "@type": "ImageObject",
                  url: "https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg",
                },
              },
              mainEntityOfPage: {
                "@type": "WebPage",
                "@id": "https://www.selftissus.fr" + location.pathname,
              },
            })}
          </script>

          {location.pathname !== "/" && (
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  ...dataAriane
                    ?.slice()
                    .sort(
                      (a, b) => dataAriane?.indexOf(b) - dataAriane?.indexOf(a)
                    )
                    .map((item, index) => ({
                      "@type": "ListItem",
                      position: index + 1,
                      name: item.Lib,
                      item:
                        index === 0
                          ? "https://www.selftissus.fr"
                          : `https://www.selftissus.fr${item.URL}`,
                    })),
                ],
              })}
            </script>

          )}
              <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Product",
          brand: {
            "@context": "http://schema.org",
            "@type": "Brand",
            name: dataPage?.Atelier?.Meta?.Meta?.Brand,
          },
          name: dataPage?.Atelier?.Meta?.Meta?.Titre,
          offers: {
            "@type": "Offer",
            priceCurrency: "EUR",
            priceValidUntil: dataPage?.Atelier?.Meta?.Meta?.TarifExpiration,
            price: dataPage?.Atelier?.Meta?.Meta?.Tarif || "default_price",
            availability: "https://schema.org/InStock",
          },
          category: dataPage?.Atelier?.Meta?.Meta?.Categorie,
          aggregateRating: {
            "@type": "AggregateRating",
            ratingValue: dataPage?.Atelier?.Meta?.Meta?.RatingValue,
            bestRating: dataPage?.Atelier?.Meta?.Meta?.BestRating,
            ratingCount: dataPage?.Atelier?.Meta?.Meta?.RatingCountp,
          },
          image: [dataPage?.Atelier?.Meta?.Meta?.URLImage],
          description: dataPage?.Atelier?.Meta?.Meta?.Description,
          sku: dataPage?.Atelier?.Meta?.Meta?.CodeSKU,
          url: "https://www.selftissus.fr" + location.pathname,
        })}
      </script>
        </Helmet>
)}

{/* Helmet Article */}
{dataPage?.Article && (
    
    <Helmet>
         <html lang="fr" />
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <link rel="icon" type="image/svg+xml" href="https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg" />
          <link rel="alternate icon" href="https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg" />
          <meta
            name="description"
            content={dataPage?.Article?.Meta?.Meta?.Description}
          />
          <link
            rel="canonical"
            href={`https://www.selftissus.fr${location.pathname}`}
          />
          <title>{dataPage?.Article?.Meta?.Meta?.Titre +" || Self Tissus"  }</title>
          <meta
            property="og:title"
            content={dataPage?.Article?.Meta?.Meta?.Titre  +" || Self Tissus"}
            key="ogtitle"
          />
          <meta
            property="og:description"
            content={dataPage?.Article?.Meta?.Meta?.Description}
            key="ogdesc"
          />
          <meta propery="og:site_name" content="Self Tissus" key="ogsitename" />
          <meta
            property="og:image"
            content={
              dataPage?.Article?.Meta?.Meta?.URLImage
                ? "https://www.selftissus.fr" +
                  dataPage?.Article?.Meta?.Meta?.URLImage
                : Logo
            }
          />
          {location && (
            <meta
              property="og:url"
              content={`https://www.selftissus.fr${location.pathname}`}
            />
          )}

          <meta
            name="twitter:title"
            content={dataPage?.Article?.Meta?.Meta?.Titre  +" || Self Tissus"}
          />
          <meta
            name="twitter:description"
            content={dataPage?.Article?.Meta?.Meta?.Description}
          />
          <meta
            name="twitter:site_name"
            content={dataPage?.Article?.Meta?.Meta?.Titre  +" || Self Tissus"}
          />
          <meta
            name="twitter:image"
            content={
              "https://www.selftissus.fr" + dataPage?.Article?.Meta?.Meta?.URLImage
                ? dataPage?.Article?.Meta?.Meta?.URLImage
                : Logo
            }
          />
          <meta name="twitter:creator" content={"Self Tissus"} />
          <meta
            name="twitter:url"
            content={`https://www.selftissus.fr${location.pathname}`}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@yogan" />

          <meta
            name="twitter:image:alt"
            content={dataPage?.Article?.Meta?.Meta?.Titre  +" || Self Tissus"}
          />
          {/* <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": dataPage?.Article?.Meta?.Meta?.Type,
              name: dataPage?.Article?.Meta?.Meta?.Titre,
              description: dataPage?.Article?.Meta?.Meta?.Description,
              url: "https://www.selftissus.fr/" + location.pathname,
            })}
          </script> */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              name: "Self Tissus",
              telephone: "+33-298901939",
              url: "https://www.selftissus.fr/",
              description:
                "Site officiel de Self Tissus: Des Tissus de qualité pour tous vos projets de couture, d'ameublement et de décoration. Retrouvez nos tissus au mètre, mercerie et patrons de couture sur notre site en ligne.",
              logo: "https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg",
              image:
                "https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg",
              email: "achats@selftissus.fr",
              openingHours: "Mo-we 09:00-17:30",
              priceRange: "$$",
              sameAs: [
                "https://www.facebook.com/SelfTissus",
                "https://www.pinterest.fr/selftissusfr/",
                "https://www.instagram.com/self_tissus/",
                "https://www.youtube.com/@selftissusfrance",
              ],
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+33-298901939",
                contactType: "customer service",
                hoursAvailable: {
                  "@type": "OpeningHoursSpecification",
                  name: "Mo-We 09:00-17:30",
                },
                availableLanguage: {
                  "@type": "Language",
                  name: "French",
                },
              },
              address: {
                "@type": "PostalAddress",
                addressLocality: "QUIMPER",
                streetAddress: "10 rue Louison Bobet",
                addressRegion: "Finistère",
                postalCode: "29000",
                telephone: "+33-298901939",
                addressCountry: {
                  "@type": "Country",
                  name: "FR",
                },
              },
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              name: "Self Tissus",
              url: "https://www.selftissus.fr" + location.pathname,
              description: dataPage?.Article?.Meta?.Meta?.Description,
              publisher: {
                "@type": "Organization",
                name: "Self Tissus",
                logo: {
                  "@type": "ImageObject",
                  url: "https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg",
                },
              },
              mainEntityOfPage: {
                "@type": "WebPage",
                "@id": "https://www.selftissus.fr" + location.pathname,
              },
            })}
          </script>

          {location.pathname !== "/" && (
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  ...dataAriane
                    ?.slice()
                    .sort(
                      (a, b) => dataAriane?.indexOf(b) - dataAriane?.indexOf(a)
                    )
                    .map((item, index) => ({
                      "@type": "ListItem",
                      position: index + 1,
                      name: item.Lib,
                      item:
                        index === 0
                          ? "https://www.selftissus.fr"
                          : `https://www.selftissus.fr${item.URL}`,
                    })),
                ],
              })}
            </script>  
            )}
             <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Product",
          brand: {
            "@context": "http://schema.org",
            "@type": "Brand",
            name: dataPage?.Article?.Meta?.Meta?.Brand,
          },
          name: dataPage?.Article?.Meta?.Meta?.Titre,
          offers: {
            "@type": "Offer",
            priceCurrency: "EUR",
            priceValidUntil: dataPage?.Article?.Meta?.Meta?.TarifExpiration,
            price: dataPage?.Article?.Meta?.Meta?.Tarif || "default_price",
            availability: "https://schema.org/InStock",
          },
          category: dataPage?.Article?.Meta?.Meta?.Categorie,
      //     review: {
      //   "@type": "Review",
      //   reviewRating: {
      //     "@type": "Rating",
      //     ratingValue: dataPage?.Article?.Meta?.Meta?.RatingValue,
      //     bestRating: dataPage?.Article?.Meta?.Meta?.BestRating,
      //   },
      // },
          aggregateRating: {
            "@type": "AggregateRating",
            ratingValue: dataPage?.Article?.Meta?.Meta?.RatingValue,
            // bestRating: dataPage?.Article?.Meta?.Meta?.BestRating,
            ratingCount: dataPage?.Article?.Meta?.Meta?.RatingCountp,
          },
          image: [dataPage?.Article?.Meta?.Meta?.URLImage],
          description: dataPage?.Article?.Meta?.Meta?.Description,
          sku: dataPage?.Article?.Meta?.Meta?.CodeSKU,
          url: "https://www.selftissus.fr" + location.pathname,
          hasMerchantReturnPolicy: {
            "@type": "MerchantReturnPolicy",
            returnPolicyCategory: "https://schema.org/Refund",
            merchantReturnDays:dataPage?.Article?.Meta?.Meta?.HasMerchantReturnPolicy?.Days,
            returnMethod: "https://schema.org/ReturnByMail",
            returnFees: "https://schema.org/FreeReturn"
          }
        })}
      </script>
            
          
        </Helmet>
        
)}
{/* Helmet Exploitant */}
{dataPage?.Exploitant && (
    
    <Helmet>
         <html lang="fr" />
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <link rel="icon" type="image/svg+xml" href="https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg" />
          <link rel="alternate icon" href="https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg" />
          <meta
            name="description"
            content={dataPage?.Exploitant?.Meta?.Meta?.Description}
          />
          <link
            rel="canonical"
            href={`https://www.selftissus.fr${location.pathname}`}
          />
          <title>{dataPage?.Exploitant?.Meta?.Meta?.Titre +" || Self Tissus"  }</title>
          <meta
            property="og:title"
            content={dataPage?.Exploitant?.Meta?.Meta?.Titre  +" || Self Tissus"}
            key="ogtitle"
          />
          <meta
            property="og:description"
            content={dataPage?.Exploitant?.Meta?.Meta?.Description}
            key="ogdesc"
          />
          <meta propery="og:site_name" content="Self Tissus" key="ogsitename" />
          <meta
            property="og:image"
            content={
              dataPage?.Exploitant?.Meta?.Meta?.URLImage
                ? "https://www.selftissus.fr" +
                  dataPage?.Exploitant?.Meta?.Meta?.URLImage
                : Logo
            }
          />
          {location && (
            <meta
              property="og:url"
              content={`https://www.selftissus.fr${location.pathname}`}
            />
          )}

          <meta
            name="twitter:title"
            content={dataPage?.Exploitant?.Meta?.Meta?.Titre  +" || Self Tissus"}
          />
          <meta
            name="twitter:description"
            content={dataPage?.Exploitant?.Meta?.Meta?.Description}
          />
          <meta
            name="twitter:site_name"
            content={dataPage?.Exploitant?.Meta?.Meta?.Titre  +" || Self Tissus"}
          />
          <meta
            name="twitter:image"
            content={
              "https://www.selftissus.fr" + dataPage?.Exploitant?.Meta?.Meta?.URLImage
                ? dataPage?.Exploitant?.Meta?.Meta?.URLImage
                : Logo
            }
          />
          <meta name="twitter:creator" content={"Self Tissus"} />
          <meta
            name="twitter:url"
            content={`https://www.selftissus.fr${location.pathname}`}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@yogan" />

          <meta
            name="twitter:image:alt"
            content={dataPage?.Exploitant?.Meta?.Meta?.Titre  +" || Self Tissus"}
          />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": dataPage?.Exploitant?.Meta?.Meta?.Type,
              name: dataPage?.Exploitant?.Meta?.Meta?.Titre,
              description: dataPage?.Exploitant?.Meta?.Meta?.Description,
              url: "https://www.selftissus.fr" + location.pathname,
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              name: "Self Tissus",
              telephone: "+33-298901939",
              url: "https://www.selftissus.fr/",
              description:
                "Site officiel de Self Tissus: Des Tissus de qualité pour tous vos projets de couture, d'ameublement et de décoration. Retrouvez nos tissus au mètre, mercerie et patrons de couture sur notre site en ligne.",
              logo: "https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg",
              image:
                "https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg",
              email: "achats@selftissus.fr",
              openingHours: "Mo-we 09:00-17:30",
              priceRange: "$$",
              sameAs: [
                "https://www.facebook.com/SelfTissus",
                "https://www.pinterest.fr/selftissusfr/",
                "https://www.instagram.com/self_tissus/",
                "https://www.youtube.com/@selftissusfrance",
              ],
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+33-298901939",
                contactType: "customer service",
                hoursAvailable: {
                  "@type": "OpeningHoursSpecification",
                  name: "Mo-We 09:00-17:30",
                },
                availableLanguage: {
                  "@type": "Language",
                  name: "French",
                },
              },
              address: {
                "@type": "PostalAddress",
                addressLocality: "QUIMPER",
                streetAddress: "10 rue Louison Bobet",
                addressRegion: "Finistère",
                postalCode: "29000",
                telephone: "+33-298901939",
                addressCountry: {
                  "@type": "Country",
                  name: "FR",
                },
              },
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              name: "Self Tissus",
              url: "https://www.selftissus.fr" + location.pathname,
              description: dataPage?.Exploitant?.Meta?.Meta?.Description,
              publisher: {
                "@type": "Organization",
                name: "Self Tissus",
                logo: {
                  "@type": "ImageObject",
                  url: "https://www.selftissus.fr/static/media/Logo.03a676c36366ef12514e02aa5ba27d62.svg",
                },
              },
              mainEntityOfPage: {
                "@type": "WebPage",
                "@id": "https://www.selftissus.fr" + location.pathname,
              },
            })}
          </script>

          {location.pathname !== "/" && (
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  ...dataAriane
                    ?.slice()
                    .sort(
                      (a, b) => dataAriane?.indexOf(b) - dataAriane?.indexOf(a)
                    )
                    .map((item, index) => ({
                      "@type": "ListItem",
                      position: index + 1,
                      name: item.Lib,
                      item:
                        index === 0
                          ? "https://www.selftissus.fr"
                          : `https://www.selftissus.fr${item.URL}`,
                    })),
                ],
              })}
            </script>  
            )}
    
            
          
        </Helmet>
        
)}
      <Router>
        <ArianeST default data={dataAriane} />
      </Router>
      <Container>
        {isLoading ? (
          <PageLoading />
        ) : (
          dataPageComp &&
          dataPageComp
            .sort((a, b) => a.Tri - b.Tri)
            .map((component, index) => {
              switch (component.ModeleType) {
                case "":
                  return null;

                case "NotFoundST":
                  return <NotFoundST />;

                case "Hero_HomeST":
                  return (
                    dataBandeau1?.Bandeau && (
                      <HeroHomeST key={component.ID} data={dataBandeau1} />
                    )
                  );

                case "TitreTxtBtnST":
                  return <TitreTxtBtnST key={component.ID} data={component} />;

                case "VideoST":
                  return <VideoST key={component.ID} data={component} />;

                case "SliderProduitsST":
                  return (
                    // <Suspense fallback={<PageLoading />}>
                    <SliderProduitsST key={component.ID} data={component} />
                  );

                case "SliderProduitsAdminST":
                  return (
                    <SliderProduitsAdminST
                      key={component.ID}
                      data={component}
                    />
                  );

                case "BlogListeMiniST":
                  return <BlogListeMiniST key={component.ID} blogs={dataPage?.WebBlog} data={component}/>;

                case "BlogListeST":
                  return <BlogListeST key={component.ID} data={component}/>;

                case "BlogFicheST":
                  return <BlogFicheST key={component.ID} data={dataPage?.WebBlog} />;

                case "NavBlogsST":
                  return <NavBlogsST key={component.ID} data={component} />;

                case "BandeauTutosST":
                  return <BandeauTutosST key={component.ID} data={component} />;

                case "TitreTextST":
                  return <TitreTextST key={component.ID} data={component} />;

                case "TextST":
                  return <TextST key={component.ID} txt={component?.Corps} />;

                case "TitreH2TextST":
                  return <TitreH2TextST key={component.ID} data={component} />;

                case "PresentationTitreST":
                  return (
                    <PresentationTitreST
                      key={component.ID}
                      titre={component?.Titre}
                    />
                  );

                case "SocialsBigST":
                  return <SocialsBigST key={component.ID} data={component} />;

                case "InteractionsST":
                  return <InteractionsST key={component.ID} data={component} />;

                case "FormLevelST":
                  return (
                    <FormLevelST
                      key={component.ID}
                      data={component?.Data}
                      titre={component?.Titre}
                    />
                  );

                case "H1_ST":
                  return <H1_ST key={component.ID} data={component} />;

                case "H2_ST":
                  return <H2_ST key={component.ID} data={component} />;

                case "H2LinesST":
                  return <H2LinesST key={component.ID} data={component} />;

                case "SliderYouTubeST":
                  return (
                    <SliderYouTubeST key={component.ID} data={component} />
                  );

                //Fiche produit
                case "ProduitST":
                  return <ArticleST key={component.ID} data={dataPage} />;

                case "SliderRealisationsST":
                  return (
                    <SliderRealisationsST key={component.ID} data={component} />
                  );

                case "RealisationsListeST":
                  return (
                    <RealisationsListeST key={component.ID} data={component} />
                  );
                case "TutosListeST":
                  return <TutosListeST key={component.ID} data={component} />;

                case "AteliersListeST":
                  return (
                    <AteliersListeST key={component.ID} data={component} />
                  );

                case "AtelierFicheST":
                  return (
                    <AtelierFicheST
                      key={component.ID}
                      data={dataPage?.Atelier}
                      cats={dataSousCategories}
                    />
                  );

                case "ProduitsListeST":
                  return (
                    // <ArticlesListeST key={component.ID} data={dataArticles} cats={dataSousCategories}/>
                    // <Suspense fallback={null}>
                    <ProduitsListeST
                      key={component.ID}
                      cats={dataSousCategories}
                      idCategorie={dataPage?.CategorieWeb?.ID}
                    />
                    // </Suspense>
                  );

                case "FormContactST":
                  return <FormContactST key={component.ID} data={component} />;

                case "FaqST":
                  return <FaqST key={component.ID} data={component} />;

                case "CardsST":
                  return (
                    <CardsST
                      key={component.ID}
                      data={component?.Data}
                      titre={component?.Titre}
                    />
                  );

                case "ImgGaucheTxtST":
                  return <ImgGaucheTxtST key={component.ID} data={component} />;
                case "ImgDroitTxtST":
                  return <ImgDroitTxtST key={component.ID} data={component} />;

                case "BlocsImgTxtST":
                  return (
                    <BlocsImgTxtST key={component.ID} data={component?.Data} />
                  );

                case "MapST":
                  return <MapST key={component.ID} />;

                case "InfosListeST":
                  return <InfosListeST key={component.ID} data={component} />;

                case "ImageLienST":
                  return <ImageLienST key={component.ID} data={component} />;

                case "ImageST":
                  return <ImageST key={component.ID} data={component} />;

                case "BoutonNoirLnkST":
                  return (
                    <BoutonNoirLnkST
                      key={component.ID}
                      href={component?.BTNUrl}
                      lib={component?.BTNLib}
                      target={component?.BTNMode ? "_blank" : "_self"}
                    />
                  );

                case "EspaceST":
                  return <EspaceST key={component.ID} />;

                case "CategorieIntroST":
                  return (
                    <CategorieIntroST
                      key={component.ID}
                      data={dataPage?.CategorieWeb}
                    />
                  );

                case "MagasinFicheST":
                  return (
                    <MagasinFicheST
                      key={component.ID}
                      data={dataPage?.Exploitant}
                    />
                  );

                case "LocalisationST":
                  return <LocalisationST key={component.ID} />;

                case "FranchiseST":
                  return <FranchiseST key={component.ID} data={component} />;

                case "MagasinsListeST":
                  return <MagasinsListeST key={component.ID} />;

                case "FormConnexionST":
                  return <FormConnexionST key={component.ID} />;

                case "PanierPaiementValideST":
                  return (
                    <PanierPaiementValideST
                      key={component.ID}
                      data={component}
                    />
                  );

                case "PanierPaiementInvalideST":
                  return (
                    <PanierPaiementInvalideST
                      key={component.ID}
                      data={component}
                    />
                  );

                case "MenuBoutonsST":
                  return <MenuBoutonsST key={component.ID} data={component} />;

                default:
                  return null;
              }
            })
        )}
      </Container>
      {/* {showRecommand && ReactDOM.createPortal(<PopUpBottom>
                <ButFermer onClick={() => {
                    setShowRecommand(false);
                    handleCloseRecommand();
                }}>X</ButFermer>
                <Wrap>
                    <FormRecommandST closeR={() => setShowRecommand(false)} />
                </Wrap>
                </PopUpBottom>,
                document.body)
                } */}
      {/* {showNL &&
        ReactDOM.createPortal(
          <PopUp position={"fixed"}>
            <ButFermer
              onClick={() => {
                setShowNL(false);
                handleCloseNL();
              }}
            >
              X
            </ButFermer>
            <Wrap>
              <FormNewsletterST close={() => setShowNL(false)} />
            </Wrap>
          </PopUp>,
          document.body
        )} */}
      {/* <Suspense fallback={null}> */}
      <Footer1ST />
      <Footer2ST />
      <Footer3ST />
      <Footer4ST />
      {/* </Suspense> */}
    </div>
  );
}
