import React, {useEffect,useRef,useState} from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import LienNoirBtnST from "../DesignSystem/SelfTissus/LienNoirBtn";
import { Swiper, SwiperSlide } from 'swiper/react';
import { register } from 'swiper/element/bundle';
import { Navigation, Autoplay } from 'swiper/modules';
import img from "../../assets/images/profile.jpg";
import BoutonYTST from "../DesignSystem/SelfTissus/BoutonYT";
import ReactPlayer from 'react-player'
import { FaYoutube } from "react-icons/fa";
import { WrapFormPop, ButFermer } from "../DesignSystem/PopUpST";
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';


const Wrapper = styled.div`
background: transparent;
width: 95%;
margin: 0 auto;
transition: all 0.5s ease-in-out;
`;

const StyledSwiper = styled(Swiper)`
padding: 20px 0; 
  .swiper-button-next,
  .swiper-button-prev {
    cursor: pointer;
    &:hover::after {
      color: ${(props) => props.theme.orangeST} !important;
    }
  }
  .swiper-button-next {
    right: 40px;
  @media (max-width: 768px) {
    right: 10px;
  }
  }
`;

const SwiperSlideStyled = styled(SwiperSlide)`
padding: 0;
border-radius: 15px;
`;



const Bloc = styled.a`
position: relative;
display: block;
text-decoration: none;
overflow: hidden;
height: 100%;
width: 100%;
height: 270px;
max-height: 270px !important;
border-radius: 15px;
margin-bottom: 20px;
&>svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 80px;
  color: #ff0033;
  transition: all 0.5s ease-in-out;
  &:hover {
    transform: translate(-50%, -50%) scale(1.08);
  }
}
&:hover {
  text-decoration: none;
}
@media screen and (max-width: 786px) {
  height: 450px;
  max-height: 450px !important;
}
  `;

const Link = styled.div`
width: 100%;
`;

const HeaderYT = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
padding: 20px 0;
@media screen and (max-width: 786px) {
  flex-direction: column;
  gap: 20px;
}
`;

const In = styled.div`
display: flex;
align-items: center;
`;

const Infos = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
span {
  margin-right: 10px;
}
`;

const Img = styled.img`
width: 70px;
height: 70px;
border-radius: 50%;
margin-right: 10px;
box-shadow: 0 0 15px 5px rgba(0,0,0,0.2);
`;

const WrapPlayer = styled.div`
width: 75%;
height: 600px;
position: absolute;
transform: translate(-50%, -50%);
top: 50%;
left: 50%;
@media screen and (max-width: 786px) {
  width: 95%;
  height: 300px;
}
`;

const Flex = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: 5px;
&>button {
  margin: 0;

}
`;

export default function SliderYouTubeST({data}) {
  const [showVideo, setShowVideo] = useState(false);
  const [urlItem, setUrlItem] = useState('');
  const handleVideoClick = (videoUrl) => {
    setShowVideo(true);
    setUrlItem(videoUrl);
  };
  const swiperRef = useRef(null);
  useEffect(() => {
    register();
    // swiperRef.current.addEventListener('slidechange', (e) => {
    //   // console.log(e.detail);
    // });

  });
  return (
      <Wrapper>
        <HeaderYT>
          <In>
          <Img src={img}/>
          <div>
            <h2 style={{margin:"0.6rem 0"}}>Self Tissus France</h2>
            <Infos>
              <span>1.4K Abonnés</span>
              <span>17 Vidéos</span>
              <span>84K Vues</span>
            </Infos>
          </div>
          </In>
          <BoutonYTST lib={"S'abonner"} href="https://www.youtube.com/channel/UCfFhwh4irLiTOkco5kQ8m8A?sub_confirmation=1" target="_blank"/>
        </HeaderYT>

      <StyledSwiper
          ref={swiperRef}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          onMouseEnter={() => {
            if (swiperRef.current) {
              swiperRef.current.autoplay.stop();
            }
          }}
          onMouseLeave={() => {
            if (swiperRef.current) {
              swiperRef.current.autoplay.start();
            }
          }}
          grabCursor={true}
          loop={true}
          // autoplay={{pauseOnMouseEnter: true, delay: 2000, disableOnInteraction:false}}
          speed={1000}
          slidesPerView={3}
          spaceBetween={20} 
          breakpoints={{
            100: {
                slidesPerView: "auto",
            },
            786: {
                slidesPerView: 3,
            },
          }}
          navigation={true}
          modules={[Navigation]}
          
        >
         
          {
            data && data?.Data.map((video, i) => { 
              return (
                <SwiperSlideStyled key={i}>
                   <Bloc >
                      <iframe width="100%" height="100%" src={video?.Corps} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                      <FaYoutube onClick={() => handleVideoClick(video?.Corps)}/>
                  </Bloc>
                  <Link>
                  <Flex><FaYoutube/><LienNoirBtnST lib={video?.BTNLib} onClick={() => handleVideoClick(video?.Corps)}/></Flex>
                  </Link>
                  
                </SwiperSlideStyled>
              );
            })
          } 
        </StyledSwiper>
        
          {showVideo && ReactDOM.createPortal(
            <WrapFormPop className="form">
              <WrapPlayer>
                <ButFermer onClick={() => setShowVideo(false)}>X</ButFermer>
              <ReactPlayer 
                url={urlItem} 
                controls={true}
                width="100%"
                height="100%"
              />
              </WrapPlayer>
            </WrapFormPop>,
            document.body
          )}
        
      </Wrapper>
  );
}